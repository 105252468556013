import React, {createContext, useCallback, useContext, useReducer} from "react";

import {notificationActions, notificationInitialState} from "./actions/notificationActions";

// combine initial states
const initialState = {
  ...notificationInitialState,
};
const StoreContext = createContext(initialState);
// combine actions
const Actions = {
  ...notificationActions,
};

const reducer = (state, { type, ...newState }) => {
  const act = Actions[type];
  const update = act(state, newState);
  return { ...state, ...update };
};

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = (store) => {
  const { state, dispatch } = useContext(StoreContext);
  const useDispatch = useCallback(dispatch, []);
  return {
    state,
    dispatch: useDispatch
  };
};
