import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from '../../../styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const QChecks = props => {
    const {classes, component, onChange} = props;
    const {input: {checks, title, disabled}} = component;

    const handleChange = name => () => {
        onChange(component, name);
    };
    return (
        <Grid container direction={checks.length > 2 ? 'column' : 'row'} wrap={'wrap'} alignItems={checks.length > 2 ? 'flex-start' : 'center'}>
            <Grid item>
                <Grid container direction={'row'} alignItems={'center'}>
                    <Typography variant={'h6'}
                                className={[classes.inputTitleColor, classes.mr1].join(' ')}
                                dangerouslySetInnerHTML={{__html: title}}/>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={checks.length > 2 ? 'column' : 'row'} className={classes.p1}>
                    {checks.map(
                        ({id, label, value}) => {
                            return (
                                <FormControlLabel
                                    key={id}
                                    label={label}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            disabled={disabled}
                                            checked={value}
                                            onChange={handleChange(id)}
                                            value={id}
                                        />
                                    }
                                />
                            );
                        }
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

QChecks.propTypes = {
    component: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(QChecks);
