import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import routes from './routes';
import User from './services/user.service';
import DownloadAppPage from './components/containers/download-app-page/download-app-page';
import {
    ALLOW_MOBILE_WEBSITE,
    API_END_POINT,
    AUTH_TOKEN,
    COMPLETE_SIGNUP,
    E_QUESTIONAR,
    FIREBASE_TOKEN
} from './constants';
import {getLang, TranslatorProvider} from "./i18n/translator.provider";
import { MuiThemeProvider } from '@material-ui/core';
import theme from "./themes";
import {ApolloProvider} from "react-apollo";
import moment from "moment";
import {LinearProgress} from "@material-ui/core";
import {restoreRequests} from "./services/offline-mutations.service";
import {InMemoryCache} from "apollo-cache-inmemory";
import {persistCache} from "apollo-cache-persist";
import {createHttpLink} from "apollo-link-http";
import {onError} from "apollo-link-error";
import RefreshTokenMutation from "./services/refresh-token.mutation.graphql";
import {setContext} from "apollo-link-context";
import {RetryLink} from "apollo-link-retry";
import {ApolloClient} from "apollo-client";
import {ApolloLink} from "apollo-link";
import FirebaseMessaging from "./services/firebase-messaging.service";
import ToggleNotification from "./components/containers/notifications/toggle-notification.graphql";
import {StoreProvider} from "./store/store.context";

const cache = new InMemoryCache();
const storage = window.localStorage;

const httpLink = createHttpLink({
    uri: API_END_POINT
});
const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(AUTH_TOKEN);
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            authorization: token ? `Bearer ${token}` : '',
            ...headers
        }
    };
});
const retry = new RetryLink({attempts: {max: 3}});

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
        graphQLErrors.map(({message, locations, path}) => {
            if (message && message.search('Action not allowed') !== -1) {
                User.logOut(client).then(() => this.props.history.push('/'));
            } else if (message === 'Token expired') {
                client.mutate({
                    mutation: RefreshTokenMutation
                }).then(
                    ({data: {RefreshToken}}) => {
                        User.token = RefreshToken;
                        window.location.reload();
                    }
                );
            }
            return console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,);
        });
    } else if (networkError) {
        if (networkError.statusCode === 410) {
            const code = User.currentIdentity.code;
            User.logOut(client);
            window.location.assign(`https://www.hu-care.com/trialended?source=mobile&code=${code}`)
        }
        console.error(`[Network error]: ${networkError}`);
    }
});

const client = new ApolloClient({
    shouldBatch: true,
    link: ApolloLink.from([authLink, errorLink, retry, httpLink]),
    cache: cache,
    connectToDevTools: process.env.NODE_ENV !== "production"
});

class App extends Component {
    state = {
        loaded: false,
        isAuth: User.isAuth()
    };

    messaging = new FirebaseMessaging({
        handleMessage: (message) => {
            console.log('Firebase push notification', message);
            const {notification: {title, body}, data: {object_subtype, object_id, schedule_id}} = message;
            // eslint-disable-next-line no-restricted-globals
            const open = confirm(`${title}\n\n${body}`);
            if (open) {
                if (object_subtype === E_QUESTIONAR) {
                    this.props.history.push(`/questionar/`, {
                        object_id: object_id,
                        schedule_id: schedule_id,
                        schedule_fulfilled: false,
                        schedule_due_date: moment().toISOString(),
                        isPassed: false
                    });
                }
            }
        }
    });

    async register() {
        const ua = navigator.userAgent.toLowerCase();
        const isUserAuth = User.isAuth();
        if (ua.indexOf('safari') !== -1 && isUserAuth) {
            if (ua.indexOf('chrome') > -1) {
                try {
                    const token = await this.messaging.requestPermission();
                    console.info('Firebase client token:', token);
                    return token;
                } catch (e) {
                    console.error(e);
                    return '';
                }
            }
        }
        return '';
    }

    static savePushToken = async (token) => {
        localStorage.setItem(FIREBASE_TOKEN, token);
        try {
            await client.mutate({
                mutation: ToggleNotification,
                variables: {
                    firebasetoken: token,
                    enable: true
                },
                fetchPolicy: 'no-cache'
            });
        } catch (e) {
            console.error(e);
        }
    };

    mobileAndTabletCheck = () => {
        let check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([-])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    downloadAppPage = () => {
        // if user is navigating the PWA
        if (window.matchMedia('(display-mode: standalone)').matches) {
            return false;
        }
        return this.mobileAndTabletCheck() && !localStorage.getItem(ALLOW_MOBILE_WEBSITE) && !this.props.location.pathname.includes('/complete-subscription') && !this.props.location.pathname.includes('/new-email-activation');
    };

    renderRoutes(isUserAuth) {
        let user;
        if (isUserAuth) {
            user = User.currentIdentity;
        }
        return routes.filter(r => r.visibility === (isUserAuth ? 'auth' : 'noAuth') || r.visibility === 'all')
            .map(r => {
                    let DynamicComponent = r.component;
                    return <Route key={r.name} path={r.routePath}
                                  render={(props) => <DynamicComponent key={r.name} title={r.title}
                                                                       user={user} {...props}/>}/>;
                }
            );
    }

    render() {
        const {loaded} = this.state;
        const isUserAuth = User.isAuth();
        let renderedRoutes = this.renderRoutes(isUserAuth);
        if (!loaded) {
            return <LinearProgress/>;
        }
        return (
            <ApolloProvider client={client}>
                <TranslatorProvider>
                    <MuiThemeProvider theme={theme}>
                        <StoreProvider>
                            <Switch>
                                {this.downloadAppPage() ? <DownloadAppPage/> : renderedRoutes}
                                {User.isAuth() ? <Redirect to="/notifications"/> :
                                    <Redirect to="/"/>}
                            </Switch>
                        </StoreProvider>
                    </MuiThemeProvider>
                </TranslatorProvider>
            </ApolloProvider>
        );
    }

    async componentDidMount(): void {
        moment().locale(getLang());
        try {
            // See above for additional options, including other storage providers.
            await persistCache({
                cache,
                storage,
                maxSize: false,
                debug: process.env.NODE_ENV !== "production"
            });
            restoreRequests(client);
        } catch (error) {
            console.error('Error restoring Apollo cache', error);
        }
        this.setState({
            loaded: true,
        });
        console.log('Apollo client is available');
        const completeLogin = !!localStorage.getItem(COMPLETE_SIGNUP);
        if (!User.isAuth() && !completeLogin) {
            this.props.history.push('/complete-subscription');
        }
    }

    async shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        const isAuth = User.isAuth();
        if (isAuth !== nextState.isAuth) {
            this.setState({isAuth});
            if (isAuth) {
                const token = await this.register();
                if (token) {
                    await App.savePushToken(token);
                }
            }
        }
        return false;
    }
}

export default withRouter(App);
