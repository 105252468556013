import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import {styles} from '../../../styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Button from '@material-ui/core/Button';
import {Link as RouterLink} from 'react-router-dom';
import {withTranslation} from "../../../i18n/translator.provider";

const Error500 = (props) => {
    const {classes, translations: {ERROR_500_MSG, CTA_BACK_TO_HOME}} = props;
    return (
        <Grid container direction={'column'} justify={'center'} className={classes.mh100} id="default-layout">
            <Grid item xs={12}>
                <Grid container direction={'row'} justify={'center'}>
                    <ErrorOutlineIcon className={classes.my3} color={'error'} style={{fontSize: '8rem'}}/>
                </Grid>
                <Grid container direction={'row'} justify={'center'}>
                    <Typography variant={'h6'}>
                        {ERROR_500_MSG}
                    </Typography>
                </Grid>
                <Grid container direction={'row'} justify={'center'}>
                    <Button variant={'text'} className={classes.my3} component={RouterLink} to={'/calendar'}>
                        {CTA_BACK_TO_HOME}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};


Error500.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation([
    'ERROR_500_MSG',
    'CTA_BACK_TO_HOME'
])(withStyles(styles)(Error500));
