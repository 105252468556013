import React, {useState} from 'react';
import DefaultLayout from "../../hoc/layouts/default";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import AppNotification from "../../ui/Notification/Notification";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LocalPharmacyIcon from "@material-ui/icons/LocalPharmacy";
import AssumptionNotifier from "../../ui/AssumptionNotifier/AssumptionNotifier";
import PatientQuery from "./patient.query.graphql";
import {fetchPolicy} from "../../../services/offline-manager";
import withStyles from "@material-ui/core/styles/withStyles";
import {styles} from "../../../styles";
import {IOS_PUSH_ID, IOS_PUSH_WEB_SERVICE} from "../../../constants";
import {withTranslation} from "../../../i18n/translator.provider";
import {compose} from "react-apollo";
import {useStore} from "../../../store/store.context";
import App from "../../../App";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";

const Notifications = ({title, classes, loading, translations: {LBL_ASSUMPTION, TXT_NO_NEW_NOTIFY, TXT_COMPILE_ASSUMPTION}}) => {
    const [notify, setNotifyAssumption] = useState(false);
    const {state: {notifications, therapies}} = useStore();
    const [selectedAssumption, setSelectedAssumption] = useState({});
    const assumedsQuery = {
        query: PatientQuery,
        variables: {
            assumed_filters: [{
                q: 'assumed_date',
                op: 'eq',
                value: moment().format('YYYY-MM-DD')
            }],
            schedules_filters: [
                {
                    q: 'schedule_notify',
                    op: 'eq',
                    value: true
                },
                {
                    q: 'schedule_fulfilled',
                    op: 'eq',
                    value: false
                },
                {
                    q: 'schedule_due_date',
                    op: 'eq',
                    value: moment().format('YYYY-MM-DD')
                }
            ]
        },
        fetchPolicy: fetchPolicy('network-only')
    };
    const assumptionDone = (assumption_id, hour) => {
        const event = new CustomEvent('refresh-assumptions-count', {detail: {assumption_id, hour}});
        window.dispatchEvent(event);
    };

    const checkRemotePermission = ({permission, deviceToken}) => {
        try {
            if (permission === 'default') {
                window.safari.pushNotification.requestPermission(
                    IOS_PUSH_WEB_SERVICE,
                    IOS_PUSH_ID,
                    {},
                    checkRemotePermission
                );
            } else if (permission === 'denied') {
                // The user said no.
            } else if (permission === 'granted') {
                // The web service URL is a valid push provider, and the user said yes.
                // permissionData.deviceToken is now available to use.
                if (permission === 'granted') {
                    console.log('Safari client token: ', deviceToken);
                    App.savePushToken(deviceToken);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };
    const notifyAssumption = (a) => ()=> {
        setSelectedAssumption(a);
        setNotifyAssumption(true);
    };
    /*
        componentDidMount(): void {
            const ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf('safari') !== -1) {
                if (ua.indexOf('chrome') <= -1) {
                    this.setState({safari: true});
                }
            }
        }
    */
    let _a = groupBy(sortBy(therapies, ({date, hour}) => moment(date).hours(hour.split(':')[0]).minutes(hour.split(':')[1])), 'date');
    return (
        <DefaultLayout title={title}>
            {notifications && notifications.map(
                (notification, index) => <AppNotification key={index} notification={notification}/>)}
            {therapies && !!therapies.length &&
            //therapies.filter((a)=> moment().isSameOrAfter(moment(a.date).hours(a.hour.split(':')[0]).minutes(a.hour.split(':')[1]))).map(
            Object.keys(_a).map(
                (key) => (
                    <div key={`${key}`}>
                        <Typography variant={'body2'} align={'center'}
                                    className={[classes.hintColor, classes.my2].join(' ')}>
                            {moment(key).format('DD MMMM YYYY')}
                        </Typography>
                        {
                            _a[key].map((a, index) =>
                                <Paper
                                    className={[classes.root, classes.m1, classes.p1, classes.topNotification, classes.therapyNotification].join(' ')}
                                    key={`${index}-${a.assumption_id}`}
                                    elevation={1} onClick={notifyAssumption(a)}>
                                    <Grid spacing={2} direction={'row'} alignItems={'center'} justify={'flex-start'}
                                          container>
                                        <Grid item>
                                            <LocalPharmacyIcon className={classes.therapyNotification}/>
                                        </Grid>
                                        <Grid item xs>
                                            <Grid container direction={'column'}>
                                                <Typography variant={'subtitle2'} className={classes.blueColor}>
                                                    {moment(a.date).hours(a.hour.split(':')[0]).minutes(a.hour.split(':')[1]).format('HH:mm')} - {TXT_COMPILE_ASSUMPTION}
                                                </Typography>
                                                <Typography variant={'caption'} color={"textSecondary"}>
                                                    {a.medicine_description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            )
                        }
                    </div>
                )
            )
            }
            {((!notifications || !notifications.length) && (!therapies || !therapies.length)) &&
            <Grid container direction={'row'} justify={'center'} className={classes.p1}>
                <Grid item xs={12}>
                    {loading &&
                    <img src="/loading.svg" alt="Loading" height="44px" className={classes.notificationLoader}/>}
                    {!loading &&
                    <Typography variant={'body2'} align={'center'}
                                className={[classes.hintColor, classes.mb2, classes.mt1].join(' ')}>
                        {TXT_NO_NEW_NOTIFY}
                    </Typography>}
                </Grid>
                {/* <Grid item xs={12}>
                        <Divider component={'hr'}/>
                    </Grid>*/}
            </Grid>}
            {/*
                {safari && !localStorage.getItem(FIREBASE_TOKEN) && (!notifications || !notifications.length) &&
                <Grid container direction={'row'} justify={'center'} className={classes.p1}>
                    <Grid item>
                        <Button color={"primary"}
                                onClick={safariPermissions}>
                            attiva notifiche
                        </Button>
                    </Grid>
                </Grid>}
*/}
            <AssumptionNotifier open={notify} assumption={selectedAssumption}
                                success={() => assumptionDone(selectedAssumption.assumption_id, selectedAssumption.hour)}
                                assumedsQuery={assumedsQuery} handleClose={() => setNotifyAssumption(false)}/>
        </DefaultLayout>
    );
};

export default compose(
    withStyles(styles),
    withTranslation([
        'TXT_NO_NEW_NOTIFY',
        'TXT_COMPILE_ASSUMPTION',
        'LBL_ASSUMPTION',
    ])
)(Notifications);
