import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from '../../../styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const QInput = props => {
    const {classes, component, onChange} = props;
    const {input: {title, subtitle, id, value, placeholder, type, disabled}} = component;

    const handleChange = event => {
        onChange(component, event.target.value);
    };
    return (
        <Grid container direction={'row'} justify={'space-between'} alignItems={'center'}>
            <Grid xs={6} item>
                <Grid container direction={'row'} alignItems={'center'}>
                    <Typography variant={'h6'} className={[classes.inputTitleColor, classes.mr1].join(' ')}
                                dangerouslySetInnerHTML={{__html: title}}/>
                    <Typography variant={'subtitle1'} className={classes.hintColor}>{subtitle}</Typography>
                </Grid>
            </Grid>
            <Grid xs={6} item>
                <Grid container direction={'row'} justify={'flex-end'} alignItems={'center'} className={classes.p1}>
                    <TextField
                        id={id}
                        value={value}
                        onChange={handleChange}
                        label={placeholder}
                        type={type}
                        disabled={disabled}
                        variant="outlined"/>
                </Grid>
            </Grid>
        </Grid>
    );
};

QInput.propTypes = {
    component: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(QInput);
