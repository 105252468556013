import React from 'react';
import PropTypes from 'prop-types';
import {styles} from '../../../styles';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import {getLabel} from "../../../i18n/translator.provider";
import User from "../../../services/user.service";

const Breadcrumb = props => {
    const {classes} = props;
    let username = User.currentIdentity;
    if(username) {
        username = username.display_name
    }
    return (
        <Paper style={{borderRadius: 0}} className={classes.p1}>
            <Typography align={'center'} color={'primary'} style={{textTransform: 'uppercase'}}>{`${username} - ${getLabel(props.title)}`}</Typography>
        </Paper>
    );
};

Breadcrumb.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string
};

export default withStyles(styles)(Breadcrumb);
