import FirebaseService from "./firebase-service";

class FirebaseMessaging {
    constructor(config = {}) {
        config = Object.assign({ handleMessage: () => {} }, config);
        this.messaging = new FirebaseService().messaging();
        this.handleMessage = config.handleMessage ;
    }

    requestPermission() {
        if (this.handleMessage) {
            this.messaging.onMessage(this.handleMessage);
        }

        return this.messaging
            .requestPermission()
            .then(() => this.messaging.getToken());
    }
}

FirebaseMessaging.defaultApp = undefined;

export default FirebaseMessaging;
