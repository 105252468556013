import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from '../../../styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import QuestionarsListItem from '../QuestionarListItem/QuestionarListItem';
import {withTranslation} from "../../../i18n/translator.provider";


const QuestionarsHistory = ({classes, questionars, translations:  {TXT_NO_QUESTIONAR_PLANNED}}) => (
    <React.Fragment>
        {!!questionars.length &&
        <Paper className={[classes.m1, classes.w100].join(' ')}>
            <div className={classes.p1}>
                <List component="nav">
                    {questionars.map((questionar, index) => {
                        return (
                            <div key={index}>
                                <QuestionarsListItem questionar={questionar}/>
                                {index !== questionars.length - 1 && <Divider className={classes.my1}/>}
                            </div>
                        );
                    })}
                </List>
            </div>
        </Paper>}
        {(!questionars.length) &&
        <Typography className={[classes.m1, classes.hintColor].join(' ')}
                    color={'inherit'} variant={'body1'} align={'center'}>{TXT_NO_QUESTIONAR_PLANNED}</Typography>}
    </React.Fragment>
);

QuestionarsHistory.propTypes = {
    classes: PropTypes.object.isRequired,
    questionars: PropTypes.array.isRequired
};

QuestionarsHistory.defaultProps = {
    questionars: []
};

export default withTranslation(['TXT_NO_QUESTIONAR_PLANNED'])(withStyles(styles)(QuestionarsHistory));
