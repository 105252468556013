import {CACHE_KEY} from '../constants';
import DashboardQuery from '../components/containers/calendar/patient.query.graphql';
import TherapyListQuery from '../components/containers/notifications/patient.query.graphql';
import TopNotificationQuery from '../components/containers/notifications/top-notification.graphql';

// Pull serialized mutations from localstorage
export const getPending = () => {
    return JSON.parse(localStorage.getItem(CACHE_KEY)) || [];
};

// Store serialized mutations in localstorage
export const setPending = (mutations) => {
    localStorage.setItem(CACHE_KEY, JSON.stringify(mutations));
};

// Return a dummy update function scoped to a request with a specific id
export const updateQuestionarState = (id, dashboardQuery, callback) => {
    return (store, {data}) => {
        if (data.__optimistic && dashboardQuery) {
            const {Compile: {schedule}} = data;
            // Read the data from our cache for this query.
            const dashboardCache = store.readQuery(dashboardQuery);
            // Add our fake data from the mutation to the end.
            dashboardCache.Patient.schedules.map((s) => {
                if (s.schedule_id === schedule.schedule_id) {
                    console.log('Update cached schedule item: ', schedule.schedule_id);
                    s.schedule_fulfilled = schedule.schedule_fulfilled;
                    /* s.compiled = {
                         answers: data.Compile.answers
                     };*/
                }
                return s;
            });
            store.writeQuery({query: DashboardQuery, variables: dashboardQuery.variables, data: dashboardCache});
            if (callback && !navigator.onLine) {
                callback(dashboardCache);
            }
        } else {
            setPending(getPending().filter(mutation => mutation.id !== id));
        }
    };
};

// Return a dummy update function scoped to a request with a specific id
export const updateAssumptionState = (id, query, callback) => {
    return (store, {data}) => {
        if (data.__optimistic && query) {
            const {TherapyNotifyAssumed} = data;
            // Read the data from our cache for this query.
            const cache = store.readQuery(query);
            // Add our fake data from the mutation to the end.
            cache.Patient.assumeds.push(TherapyNotifyAssumed);
            store.writeQuery({
                query: DashboardQuery, variables: {
                    id: query.variables.id,
                    schedules_filters: query.variables.schedules_filters,
                    assumed_filters: query.variables.assumed_filters

                }, data: cache
            });
            store.writeQuery({
                query: TherapyListQuery, variables: {
                    id: query.variables.id,
                    assumed_filters: query.variables.assumed_filters,
                    schedules_filters: query.variables.schedules_filters
                }, data: cache
            });
            store.writeQuery({
                query: TopNotificationQuery, variables: {
                    id: query.variables.id,
                    assumed_filters: query.variables.assumed_filters,
                    schedules_filters: query.variables.schedules_filters
                }, data: cache
            });
            if (callback && !navigator.onLine) {
                callback(cache);
            }
        } else {
            setPending(getPending().filter(mutation => mutation.id !== id));
        }
    };
};

export const restoreRequests = (client) => {
    getPending().forEach(({id, params, mutation}) => {
        console.log('Restore request ', mutation);
        params.optimisticResponse.__optimistic = true;
        if (mutation['TherapyNotifyAssumed']) {
            params.update = updateAssumptionState(id);
        } else if (mutation['Patient']) {
            params.update = updateQuestionarState(id);
        }
        params.mutation = mutation;
        client.mutate(params).catch(
            (e) => console.log('error restoring request', mutation, e)//setPending(getPending().filter(mutation => mutation.id !== id))
        );
    });
};
