import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from '../../../styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const QSlider = props => {
    const {classes, component, onChange} = props;
    const {input: {min, max, step, title, value, disabled}} = component;

    const handleChange = (event, value) => {
        onChange(component, value);
    };

    let sliderLength = [];
    for (let i = min; i <= max; i += step) {
        sliderLength.push(i);
    }
    return (
        <Grid container direction={'column'}>
            <Grid xs={12} item>
                <Grid container direction={'row'} alignItems={'center'}>
                    <Typography variant={'h6'} className={classes.inputTitleColor}
                                dangerouslySetInnerHTML={{__html: title}}/>
                </Grid>
            </Grid>
            <Grid xs={12} item>
                <Grid container direction={'row'} justify={'center'} alignItems={'center'}
                      className={classes.mt2}>
                    <Slider
                        disabled={disabled}
                        className={classes.p1}
                        value={value}
                        min={min}
                        max={max}
                        step={step}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid container direction={'row'} justify={'space-between'}
                      alignItems={'center'} style={{marginLeft: '5px'}}>
                    {sliderLength.map(
                        (number) => <Typography variant={'body1'} key={number}>{number}</Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

QSlider.propTypes = {
    component: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(QSlider);
