import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from '../../../styles';
import Grid from '@material-ui/core/Grid';
import Moment from 'react-moment';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import TodayIcon from '@material-ui/icons/Today';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';

const VisitListItem = ({classes, visit}) => {
    const displayVisit = ({title, schedule_due_date, schedule_fulfilled, isPassed, isToday,object:{questionar_description}}) => {
        return (
            <Grid container direction={'column'}>
                <Grid xs={12} item>
                    <Typography variant={'subtitle1'} className={[classes.strong, classes.successColor].join(' ')}>
                        {questionar_description || title}
                    </Typography>
                </Grid>
                <Grid xs={12} item>
                    <Grid container direction={'row'} justify={'space-between'}>
                        <Typography variant={'body2'} className={[classes.strong, classes.op8].join(' ')}>
                            <Moment format="DD MMM YYYY" style={{textTransform: 'capitalize'}}>{schedule_due_date}</Moment>
                        </Typography>
                        <React.Fragment>{displayVisitStatus(schedule_fulfilled, isPassed, isToday)}</React.Fragment>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    const displayVisitStatus = (schedule_fulfilled, isPassed, isToday) => {
        if (schedule_fulfilled) {
            return <CheckIcon className={classes.successColor}/>;
        } else if (isPassed) {
            return <BlockIcon color={'error'}/>;
        } else if (isToday) {
            return <TodayIcon color={"primary"}/>;
        } else {
            return <ScheduleIcon className={classes.hintColor}/>;
        }
    };

    return (
        <ListItem className={visit.isToday ? classes.leftBorderSuccess : ''}>
            <ListItemText primary={displayVisit(visit)}/>
        </ListItem>
    );
};

VisitListItem.propTypes = {
    classes: PropTypes.object.isRequired,
    visit: PropTypes.object.isRequired
};

export default withStyles(styles)(VisitListItem);
