import React, {Component} from 'react';
import {styles} from '../../../styles';
import withStyles from '@material-ui/core/styles/withStyles';
import DefaultLayout from '../../hoc/layouts/default';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import withApollo from 'react-apollo/withApollo';
import Button from '@material-ui/core/Button';
import ChangePasswordMutation from './change-password.graphql';
import ChangeEmailMutation from './change-email.graphql';
import ChangeLocaleMutation from './change-locale.mutation.graphql';
import User from '../../../services/user.service';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import {APPLE_STORE_LINK, GOOGLE_PLAY_STORE_LINK, PWA_HOSTING} from '../../../constants';
import {withTranslation} from "../../../i18n/translator.provider";
import LookupQuery from "./lookup.query.graphql";
import GetTimezonesQuery from "./timezones.query.graphql";
import ChangeTimezoneMutation from "./change-timezone.mutation.graphql";
import ChangeRightMutation from "./change-right.mutation.graphql";
import MenuItem from "@material-ui/core/MenuItem";
import {compose, graphql} from "react-apollo";
import Privacy from "../privacy/privacy";

class ProfileComponent extends Component {
    state = {
        oldEmail: User.email === 'undefined' ? '' : User.email,
        newEmail: '',
        repeatEmail: '',
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
        privacyDialog: false,
        toast: false,
        toastMsg: '',
        loading: false,
        currentLanguage: User.locale,
        currentTimezone: User.timezone,
        rightNotes: '',
        currentRight: ''
    };

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    submit = (event) => (ev) => {
        ev.preventDefault();
        const {oldEmail, newEmail, oldPassword, newPassword, repeatPassword} = this.state;
        this.setState({loading: true},
            () => {
                if (event === 'password') {
                    this.props.client.mutate({
                        mutation: ChangePasswordMutation,
                        variables: {
                            email: oldEmail,
                            password: oldPassword,
                            newpassword: newPassword,
                            newpassword_confirm: repeatPassword
                        },
                        fetchPolicy: 'no-cache'
                    }).then(
                        () => this.setState({
                            loading: false,
                            toast: true,
                            toastMsg: 'Nuova password salvata'
                        })
                    ).catch(({graphQLErrors: [{message}]}) => this.setState({
                            loading: false,
                            toast: true,
                            toastMsg: message
                        })
                    );
                } else {
                    this.props.client.mutate({
                        mutation: ChangeEmailMutation,
                        variables: {
                            url: `${PWA_HOSTING}/new-email-activation/`,
                            email: oldEmail,
                            newemail: newEmail
                        },
                        fetchPolicy: 'no-cache'
                    }).then(
                        () => this.setState({
                            loading: false,
                            toast: true,
                            toastMsg: 'Ti abbiamo inviato un\'email con le istruzioni per proseguire il reset'
                        })
                    ).catch(({graphQLErrors: [{message}]}) => this.setState({
                            loading: false,
                            toast: true,
                            toastMsg: message
                        })
                    );
                }
            });
    };

    changeLanguage = async () => {
        const {translations: {TXT_GENERIC_SUCCESS}} = this.props;
        try {
            await this.props.client.mutate({
                mutation: ChangeLocaleMutation,
                variables: {
                    locale: this.state.currentLanguage
                }
            });
            this.props.setLang(this.state.currentLanguage);
            this.setState({
                toast: true,
                toastMsg: TXT_GENERIC_SUCCESS,
                eLoading: false
            });
        } catch ({graphQLErrors: [{message}]}) {
            this.setState({
                toast: true,
                toastMsg: message,
                eLoading: false
            });
        }
    };

    changeRight = async () => {
        const {translations: {TXT_GENERIC_SUCCESS}} = this.props;
        try {
            await this.props.client.mutate({
                mutation: ChangeRightMutation,
                variables: {
                    right: this.state.currentRight,
                    notes: this.state.rightNotes
                }
            });
            this.setState({
                toast: true,
                toastMsg: TXT_GENERIC_SUCCESS,
                eLoading: false,
                currentRight: '',
                rightNotes: ''
            });
        } catch ({graphQLErrors: [{message}]}) {
            this.setState({
                toast: true,
                toastMsg: message,
                eLoading: false
            });
        }
    };
    changeTimezone = async () => {
        const {translations: {TXT_GENERIC_SUCCESS}} = this.props;
        try {
            await this.props.client.mutate({
                mutation: ChangeTimezoneMutation,
                variables: {
                    timezone: this.state.currentTimezone
                }
            });
            User.timezone = this.state.currentTimezone;
            this.setState({
                toast: true,
                toastMsg: TXT_GENERIC_SUCCESS,
                eLoading: false
            });
        } catch ({graphQLErrors: [{message}]}) {
            this.setState({
                toast: true,
                toastMsg: message,
                eLoading: false
            });
        }
    };

    closePrivacyDialog = () => () => {
        this.setState({privacyDialog: false});
    };

    render() {
        const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
        const {
            classes, title, translations: {
                TITLE_EMAIL_EDIT,
                LBL_OLD_EMAIL,
                LBL_NEW_EMAIL,
                CTA_COMPILE_TO_EDIT,
                LBL_REPEAT_NEW_EMAIL,
                TITLE_PASS_EDIT,
                CTA_COMPILE_ALL_TO_EDIT,
                LBL_OLD_PASSWORD,
                LBL_NEW_PASSWORD,
                LBL_REPEAT_NEW_PASSWORD,
                ERROR_SAME_PASSWORD,
                CTA_SAVE_NEW_MODS,
                LBL_SETTINGS,
                CTA_SWITCH,
                LBL_LANGUAGE,
                CTA_SAVE,
                TXT_TERMS_CONDITIONS,
                LBL_TIMEZONE,
                LBL_RIGHT,
                CTA_SEND,
                CTA_INSERT,
                TITLE_EXERCISE_RIGHTS,
                CTA_SELECT,
                LBL_NOTES
            },
            languages,
            timezones,
            rights
        } = this.props;
        const {oldEmail, newEmail, oldPassword, newPassword, repeatEmail, repeatPassword, toast, toastMsg, loading, currentLanguage, currentRight, currentTimezone, rightNotes, privacyDialog} = this.state;
        return (
            <DefaultLayout title={title}>
                <Grid container direction={'row'} justify={"center"} spacing={3} className={classes.py3} style={{
                    margin: 0,
                    width: '100%'
                }}>
                    <Grid item xs={12} sm={11} md={10}>
                        <Grid item>
                            <a href={iOS ? APPLE_STORE_LINK : GOOGLE_PLAY_STORE_LINK} target={'_blank'} rel={'noopener'}
                               style={{textDecoration: 'none'}}>
                                <Paper className={[classes.root, classes.m1, classes.p3].join(' ')}>
                                    <Grid container alignItems={'center'} justify={'center'}>
                                        <Grid item xs={12}>
                                            <Grid container justify={'center'}>
                                                {!iOS && <img src="/images/icons/android/google-play-store-icon.png"
                                                              alt="Google Play Store Icon" height={50}/>}
                                                {iOS && <img src="/images/icons/ios/apple-store-icon.png"
                                                             alt="Apple Store Icon" height={50}/>}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button color={'primary'} variant={'text'} size={'small'} type={'submit'}
                                                    className={[classes.mt2, classes.w100].join(' ')}>
                                                download app
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </a>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={11} md={10}>
                        <Typography variant={'subtitle1'} align={'center'}
                                    className={[classes.hintColor].join(' ')}>
                            {LBL_SETTINGS}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={11} md={10}>
                        <Paper className={[classes.root, classes.p3].join(' ')}>
                            <Grid container direction={"row"} spacing={4}>
                                <Grid item xs={12}>
                                    <Grid container alignItems={"flex-end"} spacing={2}>
                                        <Grid item xs>
                                            <TextField select={true} fullWidth={true} value={currentLanguage}
                                                       placeholder={`${CTA_SWITCH} ${LBL_LANGUAGE}`}
                                                       onChange={(e) => this.setState({currentLanguage: e.target.value})}
                                                       label={`${CTA_SWITCH} ${LBL_LANGUAGE}`} margin={"none"}>
                                                {languages.map(({short_description, value}) => (
                                                    <MenuItem key={value} value={value} component={'li'}>
                                                        {short_description}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <Button variant={"text"} onClick={this.changeLanguage}
                                                    color={"primary"}>
                                                {CTA_SAVE}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems={"flex-end"} spacing={2}>
                                        <Grid item xs>
                                            <TextField select={true} fullWidth={true} value={currentTimezone}
                                                       placeholder={`${CTA_SWITCH} ${LBL_TIMEZONE}`}
                                                       onChange={(e) => this.setState({currentTimezone: e.target.value})}
                                                       label={`${CTA_SWITCH} ${LBL_TIMEZONE}`} margin={"none"}>
                                                {timezones.map((timezone, index) => (
                                                    <MenuItem key={index} value={timezone} component={'li'}>
                                                        {timezone}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <Button variant={"text"} onClick={this.changeTimezone}
                                                    color={"primary"}>
                                                {CTA_SAVE}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={11} md={10}>
                        <Typography variant={'subtitle1'} className={[classes.hintColor].join(' ')}
                                    align={'center'}>
                            {TITLE_EXERCISE_RIGHTS}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={11} md={10}>
                        <Paper className={[classes.root, classes.p3].join(' ')}>
                            <Grid container direction={"row"} spacing={4}>
                                <Grid item xs={12}>
                                    <Grid container direction={"column"} spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField select={true} fullWidth={true} value={currentRight}
                                                       onChange={(e) => this.setState({currentRight: e.target.value})}
                                                       label={`${CTA_SELECT} ${LBL_RIGHT}`} margin={"none"}>
                                                {rights.map(({short_description, value}) => (
                                                    <MenuItem key={value} value={value} component={'li'}>
                                                        {short_description}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField fullWidth={true} multiline={true}
                                                       value={rightNotes} label={`${CTA_INSERT} ${LBL_NOTES}`}
                                                       onChange={(e) => this.setState({rightNotes: e.target.value})}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant={"text"} onClick={this.changeRight}
                                                    color={"primary"} fullWidth={true} disabled={!currentRight}>
                                                {CTA_SEND}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={11} md={10}>
                        <Typography variant={'subtitle1'} className={[classes.hintColor].join(' ')}
                                    align={'center'}>{TITLE_EMAIL_EDIT}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={11} md={10}>
                        <Paper className={[classes.root, classes.p3].join(' ')}>
                            <form autoComplete={'off'} onSubmit={this.submit('email')}>
                                <Grid container direction={'column'}>
                                    <TextField
                                        id="oldEmail"
                                        label={LBL_OLD_EMAIL}
                                        value={oldEmail}
                                        onChange={this.handleChange('oldEmail')}
                                        margin="normal"
                                        autoComplete="false"
                                        type={'email'}/>
                                    <TextField
                                        id="newEmail"
                                        label={LBL_NEW_EMAIL}
                                        value={newEmail}
                                        onChange={this.handleChange('newEmail')}
                                        margin="normal"
                                        autoComplete="false"
                                        type={'email'}/>
                                    {!newEmail &&
                                    <Typography variant={'caption'} color={'error'}>{CTA_COMPILE_TO_EDIT}</Typography>}
                                    <TextField
                                        id="repeatEmail"
                                        label={LBL_REPEAT_NEW_EMAIL}
                                        value={repeatEmail}
                                        onChange={this.handleChange('repeatEmail')}
                                        margin="normal"
                                        autoComplete="false"
                                        type={'email'}/>
                                    {!repeatEmail && <Typography variant={'caption'} color={'error'}>
                                        {CTA_COMPILE_TO_EDIT}
                                    </Typography>}
                                    {repeatEmail && newEmail !== repeatEmail &&
                                    <Typography variant={'caption'} color={'error'}>La nuova email è
                                        differente</Typography>}
                                    <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
                                        <Button color={'primary'} variant={'text'} size={'small'} type={'submit'}
                                                disabled={!oldEmail || !newEmail || (newEmail !== repeatEmail)}
                                                className={[classes.mt2, classes.w100].join(' ')}>
                                            {loading && <CircularProgress size={24} color={'primary'}/>}
                                            {!loading && CTA_SAVE}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={11} md={10}>
                        <Typography variant={'subtitle1'} className={[classes.hintColor].join(' ')}
                                    align={'center'}>
                            {TITLE_PASS_EDIT}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={11} md={10}>
                        <Paper className={[classes.root, classes.p3].join(' ')}>
                            <form autoComplete={'off'} onSubmit={this.submit('password')}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant={'body1'}>{CTA_COMPILE_ALL_TO_EDIT}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="oldPassword"
                                            label={LBL_OLD_PASSWORD}
                                            type="password"
                                            autoComplete="false"
                                            value={oldPassword} fullWidth={true}
                                            onChange={this.handleChange('oldPassword')}
                                            margin="normal"/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="newPassword"
                                            label={LBL_NEW_PASSWORD}
                                            type="password"
                                            autoComplete="false"
                                            value={newPassword} fullWidth={true}
                                            onChange={this.handleChange('newPassword')}
                                            margin="normal"/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {!newPassword &&
                                        <Typography variant={'caption'}
                                                    color={'error'}>{CTA_COMPILE_TO_EDIT}</Typography>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="repeatPassword"
                                            label={LBL_REPEAT_NEW_PASSWORD}
                                            type="password"
                                            autoComplete="false" fullWidth={true}
                                            value={repeatPassword}
                                            onChange={this.handleChange('repeatPassword')}
                                            margin="normal"/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {!repeatPassword && <Typography variant={'caption'} color={'error'}>
                                            {CTA_COMPILE_TO_EDIT}
                                        </Typography>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {repeatPassword && newPassword !== repeatPassword &&
                                        <Typography variant={'caption'} color={'error'}>
                                            {ERROR_SAME_PASSWORD}
                                        </Typography>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
                                            <Button color={'primary'} variant={'text'} size={'small'} type={'submit'}
                                                    disabled={!oldPassword || !newPassword || (newPassword !== repeatPassword)}
                                                    className={[classes.mt2, classes.w100].join(' ')}>
                                                {loading && <CircularProgress size={24} color={'primary'}/>}
                                                {!loading && CTA_SAVE_NEW_MODS}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={11} md={8}>
                        <Button color={'default'} variant={"outlined"} fullWidth
                                onClick={()=> this.setState({privacyDialog: true})}>
                            {TXT_TERMS_CONDITIONS}
                        </Button>
                    </Grid>
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    autoHideDuration={6000}
                    open={toast} onClose={() => {
                    this.setState({toast: false});
                }}
                    message={<span id="message-id">{toastMsg}</span>}/>
                <Privacy dialog={privacyDialog} handleClose={this.closePrivacyDialog} readonly/>
            </DefaultLayout>
        );
    }
}

export default compose(
    graphql(
        LookupQuery, {
            options: () => {
                return {
                    variables: {
                        lookup: 'locale'
                    }
                }
            },
            props: ({data}) => {
                if (!data.Lookup) {
                    return {
                        loading: data.loading,
                        languages: []
                    }
                }
                return {
                    languages: data.Lookup
                }
            },
        }
    ),
    graphql(
        GetTimezonesQuery, {
            props: ({data}) => {
                if (!data.GetTimezones) {
                    return {
                        loading: data.loading,
                        timezones: []
                    }
                }
                return {
                    timezones: data.GetTimezones
                }
            },
        }
    ),
    graphql(
        LookupQuery, {
            options: () => {
                return {
                    variables: {
                        lookup: 'rights'
                    }
                }
            },
            props: ({data}) => {
                if (!data.Lookup) {
                    return {
                        loading: data.loading,
                        rights: []
                    }
                }
                return {
                    rights: data.Lookup
                }
            },
        }
    ),
    withTranslation([
        'TITLE_EMAIL_EDIT',
        'LBL_OLD_EMAIL',
        'LBL_NEW_EMAIL',
        'CTA_COMPILE_TO_EDIT',
        'LBL_REPEAT_NEW_EMAIL',
        'TITLE_PASS_EDIT',
        'CTA_COMPILE_ALL_TO_EDIT',
        'LBL_OLD_PASSWORD',
        'LBL_NEW_PASSWORD',
        'LBL_REPEAT_NEW_PASSWORD',
        'ERROR_SAME_PASSWORD',
        'CTA_SAVE_NEW_MODS',
        'LBL_SETTINGS',
        'CTA_SWITCH',
        'LBL_LANGUAGE',
        'CTA_SEND',
        'CTA_SAVE',
        'LBL_TIMEZONE',
        'LBL_RIGHT',
        'CTA_INSERT',
        'TITLE_EXERCISE_RIGHTS',
        'CTA_SELECT',
        'LBL_NOTES',
        'TXT_GENERIC_SUCCESS',
        'TXT_TERMS_CONDITIONS',
    ]),
    withApollo,
    withStyles(styles)
)(ProfileComponent);
