export const API_END_POINT = process.env.NODE_ENV === "production" ? `${window.location.protocol}//${window.location.hostname.replace('app.', '')}/graphql` : 'https://trial-dev.hu-care.com/graphql';
export const PWA_HOSTING = process.env.NODE_ENV === "production" ? `${window.location.protocol}//${window.location.hostname}` : 'https://app.trial-dev.hu-care.com';
export const AUTH_TOKEN = 'HU_AUTH_TOKEN';
export const USERNAME = 'HU_USERNAME';
export const EMAIL = 'HU_EMAIL';
export const IDENTITIES = 'HU_IDENTITIES';
export const COMPLETE_SIGNUP = 'HU_COMPLETE_SIGNUP';
export const FIREBASE_TOKEN = 'HU_FIREBASE_TOKEN';
export const CALENDAR_DAY = 'HU_CALENDAR_DAY';
export const ALLOW_MOBILE_WEBSITE = 'HU_ALLOW_MOBILE_WEBSITE';
export const THERAPIES_UPDATE_DATE = 'HU_THERAPIES_UPDATE_DATE';
export const E_QUESTIONAR = 'patient';
export const E_VISIT = 'medic';
export const E_THERAPY = 'therapy';
export const CACHE_KEY = 'offline-mutations-cache';
export const IOS_PUSH_ID = 'com.hu-care-trial.TrialApp';
export const IOS_PUSH_WEB_SERVICE = 'https://trial.hu-care.com/push';
export const GOOGLE_PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.hucare.trial';
export const APPLE_STORE_LINK = 'https://itunes.apple.com/us/app/hucare-trial/id1348237607?l=it&ls=1&mt=8';
export const HU_LOCALE = 'HU_LOCALE';
export const HU_TIMEZONE = 'HU_TIMEZONE';
