import firebase from "firebase/app";
import 'firebase/messaging';

class FirebaseApp {
    constructor() {
        if (!FirebaseApp.defaultApp) {
            FirebaseApp.defaultApp = firebase.initializeApp({
                messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
            });
        }
    }

    messaging() {
        if (!firebase.messaging.isSupported()) {
            return {
                onMessage: () => {
                    console.warn('Firebase messaging not supported');
                },
                requestPermission: () => new Promise((resolve) => {
                    resolve('Firebase messaging not supported');
                })
            };
        }
        if (!FirebaseApp.messaging) {
            FirebaseApp.messaging = FirebaseApp.defaultApp.messaging();
        }
        return FirebaseApp.messaging;
    }
}

FirebaseApp.defaultApp = undefined;
FirebaseApp.messaging = undefined;

export default FirebaseApp;
