import React from "react";
import {Link as RouterLink, withRouter} from "react-router-dom";
import Typography from "@material-ui/core/Typography/index";
import Grid from "@material-ui/core/Grid/index";
import Link from "@material-ui/core/Link/index";
import Button from "@material-ui/core/Button/index";
import {withTranslation} from "../../../i18n/translator.provider";

const PageNotAllowed = ({translations: {TXT_403_DESC1, TXT_403_DESC2, CTA_BACK_TO_HOME}}) => (
    <Grid container direction={"column"} alignItems={"center"} justify={"center"}
          style={{height: '100vh'}} spacing={3}>
        <Grid item>
            <img src="/logo.svg" alt="HuCare logo" width={128}/>
        </Grid>
        <Grid item>
            <Typography variant={"h1"}>403</Typography>
        </Grid>
        <Grid item>
            <Typography variant={"h6"}>{TXT_403_DESC1}</Typography>
        </Grid>
        <Grid item>
            <Typography variant={"body1"}>{TXT_403_DESC2}</Typography>
        </Grid>
        <Grid item>
            <Link component={RouterLink} to={'/calendar'}>
                <Button color={"primary"}>{CTA_BACK_TO_HOME}</Button>
            </Link>
        </Grid>
    </Grid>
);

export default withTranslation([
    'TXT_403_DESC1',
    'TXT_403_DESC2',
    'CTA_BACK_TO_HOME'
])(withRouter(PageNotAllowed));
