import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";

export const styles = theme => ({
    root: {
        flexGrow: 1
    },
    drawerWidth: {
        width: 300,
        height: '100%'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: 300,
            flexShrink: 0,
        },
    },
    oh: {
        overflow: 'hidden'
    },
    strong: {
        fontWeight: '500'
    },
    op5: {
        opacity: .5
    },
    op8: {
        opacity: .8
    },
    mh100: {
        minHeight: '100vh'
    },
    h100: {
        height: '100%'
    },
    w75: {
        width: '75%!important'
    },
    w100: {
        width: '100%!important'
    },
    m0: {
        margin: '0'
    },
    mt1: {
        marginTop: '.5rem'
    },
    mb1: {
        marginBottom: '.5rem'
    },
    ml1: {
        marginLeft: '.5rem'
    },
    mr1: {
        marginRight: '.5rem'
    },
    my1: {
        marginTop: '.5rem',
        marginBottom: '.5rem'
    },
    mx1: {
        marginLeft: '.5rem',
        marginRight: '.5rem'
    },
    m1: {
        marginLeft: '.5rem',
        marginRight: '.5rem',
        marginTop: '.5rem',
        marginBottom: '.5rem'

    },
    mt2: {
        marginTop: '1rem'
    },
    mb2: {
        marginBottom: '1rem'
    },
    ml2: {
        marginLeft: '1rem'
    },
    mr2: {
        marginRight: '1rem'
    },
    my2: {
        marginTop: '1rem',
        marginBottom: '1rem'
    },
    mx2: {
        marginLeft: '1rem',
        marginRight: '1rem'
    },
    m2: {
        marginLeft: '1rem',
        marginRight: '1rem',
        marginTop: '1rem',
        marginBottom: '1rem'

    },
    mt3: {
        marginTop: '1.5rem'
    },
    mb3: {
        marginBottom: '1.5rem'
    },
    ml3: {
        marginLeft: '1.5rem'
    },
    mr3: {
        marginRight: '1.5rem'
    },
    my3: {
        marginTop: '1.5rem',
        marginBottom: '1.5rem'
    },
    mx3: {
        marginLeft: '1.5rem',
        marginRight: '1.5rem'
    },
    m3: {
        marginLeft: '1.5rem',
        marginRight: '1.5rem',
        marginTop: '1.5rem',
        marginBottom: '1.5rem'

    },
    mt4: {
        marginTop: '2rem'
    },
    mb4: {
        marginBottom: '2rem'
    },
    ml4: {
        marginLeft: '2rem'
    },
    mr4: {
        marginRight: '2rem'
    },
    my4: {
        marginTop: '2rem',
        marginBottom: '2rem'
    },
    mx4: {
        marginLeft: '2rem',
        marginRight: '2rem'
    },
    m4: {
        marginLeft: '2rem',
        marginRight: '2rem',
        marginTop: '2rem',
        marginBottom: '2rem'

    },
    p0: {
        padding: 0
    },
    py0: {
        paddingTop: '0',
        paddingBottom: '0'
    },
    px0: {
        paddingLeft: '0',
        paddingRight: '0'
    },
    pt1: {
        paddingTop: '.5rem'
    },
    pb1: {
        paddingBottom: '.5rem'
    },
    pl1: {
        paddingLeft: '.5rem'
    },
    pr1: {
        paddingRight: '.5rem'
    },
    py1: {
        paddingTop: '.5rem',
        paddingBottom: '.5rem'
    },
    px1: {
        paddingLeft: '.5rem',
        paddingRight: '.5rem'
    },
    p1: {
        paddingLeft: '.5rem',
        paddingRight: '.5rem',
        paddingTop: '.5rem',
        paddingBottom: '.5rem'
    },
    pt2: {
        paddingTop: '1rem'
    },
    pb2: {
        paddingBottom: '1rem'
    },
    pl2: {
        paddingLeft: '1rem'
    },
    pr2: {
        paddingRight: '1rem'
    },
    py2: {
        paddingTop: '1rem',
        paddingBottom: '1rem'
    },
    px2: {
        paddingLeft: '1rem',
        paddingRight: '1rem'
    },
    p2: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingTop: '1rem',
        paddingBottom: '1rem'

    },
    pt3: {
        paddingTop: '1.5rem'
    },
    pb3: {
        paddingBottom: '1.5rem'
    },
    pl3: {
        paddingLeft: '1.5rem'
    },
    pr3: {
        paddingRight: '1.5rem'
    },
    py3: {
        paddingTop: '1.5rem',
        paddingBottom: '1.5rem'
    },
    px3: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem'
    },
    p3: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        paddingTop: '1.5rem',
        paddingBottom: '1.5rem'
    },
    pt4: {
        paddingTop: '2rem'
    },
    pb4: {
        paddingBottom: '2rem'
    },
    pl4: {
        paddingLeft: '2rem'
    },
    pr4: {
        paddingRight: '2rem'
    },
    py4: {
        paddingTop: '2rem',
        paddingBottom: '2rem'
    },
    px4: {
        paddingLeft: '2rem',
        paddingRight: '2rem'
    },
    p4: {
        paddingLeft: '2rem',
        paddingRight: '2rem',
        paddingTop: '2rem',
        paddingBottom: '2rem'

    },
    secondaryFormControlColor: {
        color: theme.palette.secondary.main,
        '& label': {
            color: theme.palette.secondary.main,
        },
        '& div': {
            color: theme.palette.secondary.main,
            '&:before': {
                borderColor: theme.palette.secondary.main
            }
        }
    },
    primaryColor: {
        color: theme.palette.primary.main,
    },
    successColor: {
        color: theme.palette.success.main,
    },
    hintColor: {
        color: theme.palette.text.hint,
    },
    blueColor: {
        color: `rgb(13, 136, 241)`,
    },
    yellowColor: {
        color: '#f1b80d',
    },
    backGroundApp: {
        backgroundColor: '#F5F7FC',
    },
    dividerColor: {
        backgroundColor: '#FAFAFA',
    },
    leftBorderPrimary: {
        borderLeft: '1px solid ' + theme.palette.primary.main
    },
    leftBorderYellow: {
        borderLeft: '1px solid #f1b80d'
    },
    leftBorderSuccess: {
        borderLeft: '1px solid ' + theme.palette.success.main
    },
    primaryBackground: {
        backgroundColor: theme.palette.primary.main + '!important',
    },
    yellowBackground: {
        backgroundColor: '#f1b80d!important',
    },
    hintBackground: {
        backgroundColor: theme.palette.text.hint + '!important',
    },
    successBackground: {
        backgroundColor: theme.palette.success.main + '!important',
    },
    errorBackground: {
        backgroundColor: theme.palette.error.main + '!important',
    },
    topNotification: {
        cursor: 'pointer',
        borderLeft: '8px solid'
    },
    primaryNotification: {
        borderColor: theme.palette.primary.main
    },
    easyNotification: {
        borderColor: theme.palette.success.main
    },
    therapyNotification: {
        borderColor: `rgb(13, 136, 241)`,
        color: `rgb(13, 136, 241)`
    },
    blueChip: {
        backgroundColor: `rgb(13, 136, 241)!important`
    },
    errorBtnOutline: {
        color: red[500],
        borderColor: red[500]
    },
    errorBtn: {
        backgroundColor: `${red[500]}!important`,
        color: 'white'
    },
    successBtnOutline: {
        color: green[500],
        borderColor: green[500]
    },
    successBtn: {
        backgroundColor: `${green[500]}!important`,
        color: 'white'
    },
    calendarBugFix: {
        '& > div': {
            position: 'relative',
            width: '100%',
            '& > div': {
                margin: '0 auto',
                '& > div': {
                    minHeight: '400px',
                    '& > div': {
                        '& > div': {
                            minHeight: '400px',
                            '& .questionar, .visit': {
                                '&:after': {
                                    content: '""',
                                    borderRadius: '100%',
                                    width: '8px',
                                    height: '8px',
                                    position: 'absolute',
                                    marginTop: '25px'
                                }
                            },
                            '& .questionar': {
                                '&:after': {
                                    backgroundColor: '#f1b80d'
                                }
                            },
                            '& .visit': {
                                '&:after': {
                                    backgroundColor: theme.palette.success.main
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    customSelectedDay: {
        backgroundColor: 'transparent!important',
        background: 'url(/yellow-circle.svg) center center no-repeat',
    },
    eventChip: {
        width: '12px',
        height: '12px',
        backgroundColor: 'black',
        borderRadius: '100%',
        flexShrink: '0',
    },
    inputTitleColor: {
        color: '#595959'
    },
    notificationLoader: {
        margin: '0 auto',
        display: 'block'
    },
    appVersionWrapper: {
        position: 'absolute',
        bottom: 0,
        right: 0
    }
});
