import {createMuiTheme} from '@material-ui/core/styles';
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";

let theme = createMuiTheme({
    typography: {
        useNextVariants: true
    },
    palette: {
        primary: {
            main: '#0E88F1',
            contrastText: '#efefef'
        },
        secondary: {
            main: '#ffffff',
            dark: '#f9f9f9',
            contrastText: '#0E88F1'
        },
        dark: {
            main: '#232323',
            contrastText: '#efefef'
        },
        success: {
            main: '#77c97d',
            contrastText: '#efefef'
        },
        error: {
            main: '#d52821',
            contrastText: '#efefef'
        },
        text: {
            hint: '#afafaf',
            error: '#d52821',
            success: '#77c97d'
        }
        // error: will use the default color
    },
});
theme = responsiveFontSizes(theme);

export default theme