import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {styles} from '../../../styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Lock from '@material-ui/icons/Lock';
import Email from '@material-ui/icons/Email';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import {COMPLETE_SIGNUP} from '../../../constants';
import LoginMutation from './login.graphql';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import User from '../../../services/user.service';
import withApollo from 'react-apollo/withApollo';
import Button from "@material-ui/core/Button";
import {withTranslation} from "../../../i18n/translator.provider";
import {compose} from "react-apollo";

class LoginComponent extends Component {
    state = {
        email: '',
        password: '',
        error: false,
        errorMsg: '',
        loading: false
    };

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    submit = async (event) => {
        event.preventDefault();
        const {email, password} = this.state;
        localStorage.setItem(COMPLETE_SIGNUP, true);
        this.setState({loading: true});
        try {
            const {data: {Login}} = await this.props.client.mutate({
                mutation: LoginMutation,
                variables: {
                    email,
                    password
                },
                fetchPolicy: 'no-cache'
            });
            User.login({email: this.state.email, ...Login});
            this.props.setLang(Login.locale);
            if (User.identities.length > 1) {
                this.props.history.push(`/select-identity`);
            } else {
                this.props.history.push(`/`);
            }
        } catch (e) {
            this._error(e)
        }
    };

    render() {
        const {classes, translations: {LBL_EMAIL, LBL_PASSWORD, LBL_OR, TXT_LOGIN_APP_PAYOFF, CTA_LOGIN, TXT_FIRST_ACC_APP_PAYOFF, CTA_PASS_RESET}} = this.props;
        const {email, password, error, errorMsg, loading} = this.state;
        const inputFields = [{
            id: 'email',
            label: LBL_EMAIL,
            type: 'email',
            value: email,
            icon: <Email/>
        }, {
            id: 'password',
            label: LBL_PASSWORD,
            type: 'password',
            value: password,
            icon: <Lock/>
        }];
        return (
            <form onSubmit={this.submit}>
                <Grid direction={'column'} alignItems={'center'} justify={'space-evenly'}
                      className={[classes.primaryBackground, classes.mh100].join(' ')} container>
                    <Grid item xs={8}>
                        <img src="/logo-horizontal.svg" alt="HuCare logo" style={{height: '140px'}}/>
                    </Grid>
                    <Typography component="div" variant={'h5'} color={'secondary'}
                                align={'center'} gutterBottom>
                        {TXT_LOGIN_APP_PAYOFF}
                    </Typography>
                    <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                        {inputFields.map(input => (
                            <Grid container direction={'row'} alignItems={'flex-end'} justify={'center'}
                                  key={input.id}>
                                <FormControl
                                    className={[classes.mb2, classes.w75, classes.secondaryFormControlColor].join(' ')}>
                                    <InputLabel htmlFor="input-with-icon-adornment">{input.label}</InputLabel>
                                    <Input id={input.id} label={input.label} type={input.type}
                                           placeholder={input.label}
                                           value={input.value} onChange={this.handleChange(input.id)}
                                           startAdornment={
                                               <InputAdornment position="start">
                                                   {input.icon}
                                               </InputAdornment>
                                           }/>
                                </FormControl>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container direction={'column'} justify={'center'}>
                        <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
                            <Grid xs={8} item>
                                <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
                                    <Fab color={'secondary'} variant={'extended'} size={'medium'}
                                         className={[classes.my3, classes.w75].join(' ')}
                                         disabled={!email || !password} type="submit">
                                        {!loading && CTA_LOGIN}
                                        {loading && <CircularProgress size={24} color={'primary'}/>}
                                    </Fab>

                                </Grid>
                                <Grid xs={12} item>
                                    <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
                                        <Link component={RouterLink} to="/complete-subscription">
                                            <Button color={"secondary"}>{TXT_FIRST_ACC_APP_PAYOFF}</Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption" color={'secondary'}
                                                style={{textTransform: 'uppercase∂'}}
                                                align={'center'} component={'div'} gutterBottom>
                                        {LBL_OR}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
                                        <Link component={RouterLink} to="/reset-password">
                                            <Button color={'secondary'}>{CTA_PASS_RESET}</Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={error}
                    autoHideDuration={6000}
                    onClose={() => this.setState({error: false})}
                    ContentProps={{
                        'aria-describedby': 'error-message',
                    }}
                    message={<span id="error-message">{errorMsg}</span>}
                />
            </form>
        );
    }

    _error = (data) => {
        const {translations: {ERROR_401_MSG, ERROR_500_MSG}} = this.props;
        if (data) {
            switch (data.message) {
                case 'GraphQL error: Access violation: User invalid':
                case 'GraphQL error: Access violation: No valid identity found':
                    this.setState({errorMsg: ERROR_401_MSG});
                    break;
                default:
                    this.setState({errorMsg: ERROR_500_MSG});
                    break;
            }
        }
        this.setState({loading: false, error: true});
    };
}

export default compose(
    withTranslation([
        'LBL_EMAIL',
        'LBL_PASSWORD',
        'LBL_OR',
        'TXT_LOGIN_APP_PAYOFF',
        'CTA_LOGIN',
        'TXT_FIRST_ACC_APP_PAYOFF',
        'CTA_PASS_RESET',
        'ERROR_401_MSG',
        'ERROR_500_MSG'
    ]),
    withApollo,
    withRouter,
    withStyles(styles)
)(LoginComponent);
