import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

const Privacy = ({dialog, readonly, handleClose}) => {
    return (
        <Dialog open={dialog} onClose={handleClose} aria-labelledby="form-dialog-title" fullScreen maxWidth={"md"}>
            <DialogTitle id="form-dialog-title">
                <Typography variant={"h6"} align={"center"}>
                    Termini e condizioni
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container direction={"column"} spacing={1}>
                        <Grid item>
                            <Typography variant={"h5"} align={"center"}>
                                Privacy Policy di Compliance Srl
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"h6"} align={"center"}>
                                Termini e condizioni di utilizzo HuCare Trial
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"subtitle2"} gutterBottom>
                                Informativa ai sensi del decreto legislativo 30 giugno 2003 n.196, aggiornato dal
                                regolamento europeo sulla protezione dei dati (UE) 2016/679
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body1"} gutterBottom>
                                Gentile Utente, Il Software HuCare Trial è un prodotto di Compliance Srl utilizzato per
                                lo studio a
                                cui é stato invitato/a dal Titolare del progetto.
                                Si tratta di un sistema integrato, composto da una piattaforma web e da un’applicazione
                                per
                                dispositivi mobili iOS e Android, che permette la raccolta di taluni suoi Dati personali
                                durante il
                                corso dell’applicazione terapeutica e di leggerli in modo aggregato con quelli che avrà
                                cura di
                                inserire il suo medico curante sulla medesima piattaforma. La raccolta e conservazione
                                dei dati
                                avverrà solo a seguito della sua iscrizione al Servizio, la quale avrà luogo una volta
                                completati i
                                passaggi che le sono stati comunicati via email dal Software da parte del medico
                                curante,
                                all’indirizzo di posta elettronica da lei rilasciato.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body1"} gutterBottom>
                                Completata tale fase, il suo indirizzo email sarà immediatamente eliminato in modo
                                irreversibile
                                dal Sofware HuCare Trial, al fine di rendere totalmente anonima la provenienza dei suoi
                                dati ai
                                sensi dell’art. 4 punto 5 del regolamento Ue 2016/679 GDPR, nel rispetto della sua
                                riservatezza.
                            </Typography>
                        </Grid>
                        <Grid item container justify={'center'}>
                            <Grid item>
                                <Box m={2}>
                                    <Link align={'center'} href={'https://trial.hu-care.com/app/privacy-patient'}
                                          target={'_blank'}>
                                        Termini e condizioni complete su hu-care.com
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose(false)} color="default">
                    Chiudi
                </Button>
                {!readonly &&
                <Button onClick={handleClose(true)} color="primary">
                    Accetto
                </Button>}
            </DialogActions>
        </Dialog>
    );
};

export default Privacy;
