import _ from 'lodash';

export class Question {
    question_definition;
    question_id;
    question_render;
    question_text;
    question_type;
    component;

    constructor({
                    question_definition,
                    question_id,
                    question_render,
                    question_text,
                    question_type,
                    disabled
                }) {
        this.question_definition = JSON.parse(question_definition);
        this.question_id = question_id;
        this.question_text = question_text;
        this.question_type = question_type;
        this.question_render = question_render;
        switch (question_type) {
            case 'number':
                if (this.question_definition.min && this.question_definition.max) {
                    this.component = {
                        id: question_id,
                        type: 'QSlider',
                        input: {
                            id: `slider-${question_id}`,
                            title: question_text,
                            value: parseInt(this.question_definition.min),
                            min: parseInt(this.question_definition.min),
                            max: parseInt(this.question_definition.max),
                            step: 1,
                            disabled: disabled
                        }
                    };
                } else {
                    this.component = {
                        id: question_id,
                        type: 'QInput',
                        input: {
                            id: `number-${question_id}`,
                            type: 'number',
                            placeholder: '',
                            title: question_text,
                            subtitle: '',
                            value: '',
                            disabled: disabled
                        }
                    };
                }
                break;
            case 'enum':
                this.component = {
                    id: question_id,
                    type: 'QRadio',
                    input: {
                        id: `radio-${question_id}`,
                        title: question_text,
                        value: '0',
                        disabled: disabled,
                        radios: _.map(this.question_definition, (v, k) => {
                            return {
                                id: `input-radio-${question_id}-${k}`,
                                label: v,
                                value: k
                            };
                        })
                    }
                };
                break;
            case 'text':
                this.component = {
                    id: question_id,
                    type: 'QText',
                    input: {
                        id: `text-${question_id}`,
                        title: question_text,
                        placeholder: '',
                        maxLength: 250,
                        value: '',
                        disabled: disabled
                    }
                };
                break;
            case 'tristate':
                this.component = {
                    id: question_id,
                    type: 'QRadio',
                    input: {
                        id: `radio-${question_id}`,
                        title: question_text,
                        value: '2',
                        disabled: disabled,
                        radios: _.map(this.question_definition, (v, k) => {
                            return [
                                {
                                    id: `input-radio-${question_id}-${k}`,
                                    label: v,
                                    value: '2'
                                },
                                {
                                    id: `input-radio-${question_id}-yes`,
                                    label: 'yes',
                                    value: '1'
                                },
                                {
                                    id: `input-radio-${question_id}-no`,
                                    label: 'no',
                                    value: '0'
                                }
                            ];
                        })[0]
                    }
                };

                break;
            default:
                console.error('Invalid question type: ', question_type);
                this.component = {
                    input: {}
                };
                break;
        }
    }
}
