import React, {Component} from 'react';
import {compose, graphql} from "react-apollo";
import QueryTherapyCalendar from "./therapy-assumptions.query.graphql";
import DefaultLayout from "../../hoc/layouts/default";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ReportIcon from "@material-ui/icons/Report";
import Divider from "@material-ui/core/Divider";
import {withRouter} from "react-router";
import {CircularProgress} from "@material-ui/core";
import PatientQuery from "../notifications/patient.query.graphql";
import moment from "moment";
import orderBy from "lodash/orderBy";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CheckIcon from "@material-ui/icons/Check";
import NotificationsService from "../../../services/notifications.service";
import withStyles from "@material-ui/core/styles/withStyles";
import {styles} from "../../../styles";
import {fetchPolicy} from "../../../services/offline-manager";
import {withTranslation} from "../../../i18n/translator.provider";
import Button from "@material-ui/core/Button";
import withApollo from "react-apollo/withApollo";

const rangeDays = 7;


class TherapyList extends Component {
    state = {
        multiCount: 1,
        loading: this.props.loading1 || this.props.loading2,
        days: {}
    };

    loadMore = async () => {
        this.setState({loading: true});
        try {
            const {data: {TherapyGetCalendar}} = await this.props.client.query({
                query: QueryTherapyCalendar,
                variables: {
                    ref_date: moment().subtract(rangeDays * (this.state.multiCount + 1), 'day').format('DD-MM-YYYY'),
                    days: rangeDays
                },
                fetchPolicy: fetchPolicy()
            });
            const assumptions = orderBy(TherapyGetCalendar, 'date', 'desc');
            const start = moment().subtract(rangeDays * (this.state.multiCount + 1), 'day').format('YYYY-MM-DD');
            const end = moment().subtract(rangeDays * (this.state.multiCount), 'day').format('YYYY-MM-DD');
            const {data: {Patient}} = await this.props.client.query({
                query: PatientQuery,
                variables: {
                    assumed_filters: [
                        {
                            q: 'assumed_date',
                            op: 'dt',
                            values: [start, end]
                        }
                    ]
                },
                fetchPolicy: fetchPolicy()
            });
            const assumeds = Patient.assumeds;
            this.setState({
                multiCount: this.state.multiCount + 1,
                assumptions: [
                    ...this.state.assumptions,
                    ...assumptions
                ],
                assumeds: [
                    ...this.state.assumeds,
                    ...assumeds
                ]
            }, () => this.createList());
        } catch (e) {
            console.error(e);
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot): void {
        const {assumptions = [], assumeds = [], loading1, loading2} = this.props;
        if ((prevProps.loading1 || prevProps.loading2) && (!loading1 && !loading2)) {
            // console.log('Data is available', assumptions, assumeds)
            this.setState({assumptions, assumeds}, () => this.createList());
            return true
        }
        return false
    }

    createList = () => {
        const {assumptions, assumeds, days} = this.state;
        let therapies = NotificationsService.setAssumptionStatus(assumptions, assumeds);
        const _assumptions = therapies.filter(({life}) => life >= 0);
        if (!_assumptions.length) {
            this.setState({loading: false, days});
        } else {
            _assumptions.forEach(
                (assumption, index) => {
                    const {date} = assumption;
                    if (days[date]) {
                        days[date].push({
                            ...assumption,
                            index: index
                        });
                    } else {
                        days[date] = [{
                            ...assumption,
                            index: index
                        }];
                    }
                    if (index === _assumptions.length - 1) {
                        this.setState({loading: false, days});
                    }
                }
            );
        }
    };

    render() {
        const {title, history, classes, translations: {TITLE_THERAPY_HISTORY, TXT_NO_THERAPY_ASSIGNED, TXT_NO_MEDICINE_REGISTERED, LBL_ASSUMPTION, LBL_HOUR}, loading1, loading2} = this.props;
        const {days, loading} = this.state;
        return (
            <DefaultLayout title={title}>
                <Grid container justify={"center"}>
                    <Grid item xs={11} md={10} lg={8}>
                        <Grid container justify={"center"} spacing={3}>
                            <Grid item xs={12}>
                                <Grid container direction={"column"}>
                                    <Grid item>
                                        <Typography variant={"h6"} component={'p'}
                                                    className={[classes.hintColor, classes.my1].join(' ')}
                                                    align={"center"}>
                                            {TITLE_THERAPY_HISTORY}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {(loading1 || loading2) &&
                            <Grid item>
                                <CircularProgress size={24} color={'primary'}/>
                            </Grid>}
                            {!loading1 && !loading2 && !Object.keys(days).length &&
                            <Grid item xs={12}>
                                <Typography variant={"body2"} color={"textSecondary"} align={"center"}>
                                    {TXT_NO_THERAPY_ASSIGNED}
                                </Typography>
                            </Grid>}
                            {!loading1 && !loading2 && Object.keys(days).map((key, index) => {
                                days[key] = orderBy(days[key], 'hour');
                                //today is the day
                                if (moment(key).isSame(moment(), "day")) {
                                    days[key] = days[key].filter(({status}) => status !== 'close' && status !== 'open');
                                }
                                return (
                                    <Grid item key={index} xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant={"button"} color={"textSecondary"}>
                                                    {moment(key).format('DD MMMM YYYY')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper>
                                                    <List component={'ul'}>
                                                        {!days[key].length &&
                                                        <ListItem>
                                                            <Typography variant={"body2"} color={"textSecondary"}
                                                                        align={"center"}>
                                                                {TXT_NO_MEDICINE_REGISTERED}
                                                            </Typography>
                                                        </ListItem>}
                                                        {days[key].map((assumption, i) => {
                                                            const {hour, medicine_description, assumption_id, medicine_posology, status, assumed_hour} = assumption;
                                                            return (
                                                                <Grid key={i} container justify={"center"}>
                                                                    <Grid item xs={12}>
                                                                        <ListItem button={true} component={'li'}
                                                                                  onClick={() => history.push(`therapy-details/${assumption_id}`)}>
                                                                            <Grid container alignItems={"center"}
                                                                                  spacing={2}>
                                                                                <Grid item xs
                                                                                      style={{overflow: 'hidden'}}>
                                                                                    <Grid container
                                                                                          alignItems={"baseline"}
                                                                                          style={{overflow: 'hidden'}}>
                                                                                        <Grid item xs={12}>
                                                                                            <Typography
                                                                                                className={classes.blueColor}
                                                                                                variant={"subtitle2"}
                                                                                                noWrap>
                                                                                                {medicine_description}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Typography
                                                                                                variant={"subtitle2"}
                                                                                                style={{textTransform: 'capitalize'}}>
                                                                                                {LBL_HOUR} {hour}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Typography
                                                                                                className={classes.hintColor}
                                                                                                style={{marginLeft: '.2rem'}}
                                                                                                variant={"subtitle2"}>
                                                                                                ({LBL_ASSUMPTION}: {assumed_hour || 'N/D'})
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={12}
                                                                                              style={{overflow: 'hidden'}}>
                                                                                            <Typography
                                                                                                variant={"caption"}
                                                                                                color={"textSecondary"}
                                                                                                noWrap component={'p'}>
                                                                                                {medicine_posology}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    {status === 'done' &&
                                                                                    <CheckIcon
                                                                                        className={classes.successColor}/>}
                                                                                    {status === 'not_done' &&
                                                                                    <NotInterestedIcon
                                                                                        color={"error"}/>}
                                                                                    {status === 'close' &&
                                                                                    <ReportIcon color={'primary'}/>}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </ListItem>
                                                                    </Grid>
                                                                    {days[key].length - 1 !== i &&
                                                                    <Grid item xs={12}>
                                                                        <Divider component={'hr'}/>
                                                                    </Grid>}
                                                                </Grid>
                                                            )
                                                        })}
                                                    </List>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                            {!loading1 && !loading2 &&
                            <Grid item container justify={"center"}>
                                <Button color={"primary"} onClick={this.loadMore}>
                                    {!loading && 'carica altri'}
                                    {loading && <CircularProgress size={24} color={'primary'}/>}
                                </Button>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </DefaultLayout>
        );
    }
}

TherapyList.propTypes = {};

export default compose(
    graphql(QueryTherapyCalendar, {
        props: ({data}) => {
            if (!data.TherapyGetCalendar) {
                return {
                    loading1: data.loading
                }
            }
            // console.log('QueryTherapyCalendar', data.loading, data.TherapyGetCalendar)
            const assumptions = orderBy(data.TherapyGetCalendar, 'date', 'desc');
            return {
                loading1: data.loading,
                assumptions
            }
        },
        options: () => ({
            variables: {
                ref_date: moment().subtract(rangeDays, 'day').format('DD-MM-YYYY'),
                days: rangeDays
            },
            fetchPolicy: fetchPolicy()
        })
    }),
    graphql(PatientQuery, {
        props: ({data}) => {
            if (!data.Patient) {
                return {
                    loading2: data.loading
                }
            }
            // console.log('PatientQuery', data.loading, data.Patient.assumeds)
            return {
                loading2: data.loading,
                assumeds: data.Patient.assumeds
            }
        },
        options: () => {
            const start = moment().subtract(rangeDays, 'day').format('YYYY-MM-DD');
            const end = moment().format('YYYY-MM-DD');
            return {
                variables: {
                    assumed_filters: [
                        {
                            q: 'assumed_date',
                            op: 'dt',
                            values: [start, end]
                        }
                    ]
                },
                fetchPolicy: fetchPolicy()
            }
        }
    }),
    withApollo,
    withRouter,
    withStyles(styles),
    withTranslation([
        'TXT_NO_MEDICINE_REGISTERED',
        'LBL_ASSUMPTION',
        'LBL_HOUR',
        'TITLE_THERAPY_HISTORY',
        'TXT_NO_THERAPY_ASSIGNED'
    ])
)(TherapyList);
