import {compose} from "react-apollo";
import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {styles} from '../../../styles';
import withStyles from '@material-ui/core/styles/withStyles';
import withRouter from 'react-router-dom/withRouter';
import withApollo from 'react-apollo/withApollo';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom';
import SubscribeResetPassword from '../reset-password/subscribe-reset-password.graphql';
import Snackbar from '@material-ui/core/Snackbar';
import {withTranslation} from "../../../i18n/translator.provider";

class NewEmailActivationPage extends Component {
    state = {
        loading: false,
        success: false,
        token: null,
        password: '',
        repeatPassword: ''
    };

    letsGo = () => {
        this.props.history.push('/');
    };

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    submit = (ev) => {
        ev.preventDefault();
        const {token, password, repeatPassword} = this.state;
        const {client} = this.props;
        client.mutate({
            mutation: SubscribeResetPassword,
            variables: {
                token: token,
                newpassword: password,
                newpassword_confirm: repeatPassword
            },
            context: {
                headers: {
                    authorization: ''
                }
            },
            fetchPolicy: 'no-cache'
        }).then(
            () => this.setState({
                loading: false,
                toast: true,
                toastMsg: 'Nuova email attiva!',
                success: true
            })).catch(
            ({graphQLErrors: [{message}]}) => this.setState({
                loading: false,
                toast: true,
                toastMsg: this.getErrorMessage(message)
            })
        );
    };

    getErrorMessage(message) {
        const {
            translations: {
                ERROR_ACTIVE_SESSION,
                ERROR_INVALID_TOKEN,
                ERROR_500_MSG
            }
        } = this.props;
        switch (message) {
            case 'Action not allowed: scope patient not eq guest':
                return ERROR_ACTIVE_SESSION;
            case 'Missing or Invalid resource: Invite not found':
                return ERROR_INVALID_TOKEN;
            default:
                return ERROR_500_MSG;
        }
    }

    componentDidMount(): void {
        this.setState({token: this.props.location.search.split('hucaretoken=')[1]});
    }

    render() {
        const {classes} = this.props;
        const {loading, success, toast, toastMsg} = this.state;
        const {
            translations: {
                TITLE_EMAIL_ACTIVATION,
                TXT_EMAIL_ACTIVATION,
                TXT_NEW_EMAIL_ACTIVATED,
                CTA_GOTO_WEBSITE,
                CTA_EMAIL_ACTIVATION,
                CTA_BACK_TO_LOGIN
            }
        } = this.props;
        return (
            <form onSubmit={this.submit} autoComplete={'off'}>
                <Grid container justify={'center'} alignItems={'center'}
                      style={{
                          position: 'fixed',
                          left: '0',
                          right: '0',
                          top: '0',
                          bottom: '0',
                          background: '#0E88F1',
                          overflow: 'auto'
                      }} className={[classes.primaryBackground, classes.mh100].join(' ')}>
                    <Grid xs={10} item>
                        <Grid container direction={'column'} spacing={3}>
                            <Grid item>
                                <Grid container justify={'center'}>
                                    <img src="/logo-horizontal.svg" alt="HuCare logo" style={{height: '140px'}}/>
                                </Grid>
                            </Grid>
                            {!success &&
                            <Grid item>
                                <Grid container direction={'column'}>
                                    <Typography variant={'h6'} color={'secondary'} align={'center'}>
                                        {TITLE_EMAIL_ACTIVATION}
                                    </Typography>
                                    <Typography variant={'subtitle1'} color={'secondary'} align={'center'}>
                                        {TXT_EMAIL_ACTIVATION}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            {success &&
                            <Grid item>
                                <Typography variant={'h6'} color={'secondary'} align={'center'}>
                                    {TXT_NEW_EMAIL_ACTIVATED}
                                </Typography>
                            </Grid>}
                            {success && <Grid item>
                                <Grid container justify={'center'}>
                                    <Button variant={'outlined'} color={'secondary'} className={classes.mt2}
                                            onClick={this.letsGo}>
                                        {CTA_GOTO_WEBSITE}
                                    </Button>
                                </Grid>
                            </Grid>}
                            {!success && <Grid item>
                                <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
                                    <Fab color={'secondary'} variant={'extended'} size={'medium'}
                                         className={classes.w75} type={'submit'}>
                                        {loading && <CircularProgress size={24} color={'primary'}/>}
                                        {!loading && CTA_EMAIL_ACTIVATION}
                                    </Fab>
                                </Grid>
                            </Grid>}
                            {!success && <Grid item>
                                <Link component={RouterLink} to="/login">
                                    <Typography variant="body1" align={'center'} color={'secondary'}>
                                        {CTA_BACK_TO_LOGIN}
                                    </Typography>
                                </Link>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={toast} onClose={() => {
                    this.setState({toast: false});
                }}
                    message={<span id="message-id">{toastMsg}</span>}/>
            </form>
        );
    }
}

export default compose(
    withTranslation([
        'TITLE_EMAIL_ACTIVATION',
        'TXT_EMAIL_ACTIVATION',
        'TXT_NEW_EMAIL_ACTIVATED',
        'CTA_GOTO_WEBSITE',
        'CTA_EMAIL_ACTIVATION',
        'CTA_BACK_TO_LOGIN',
        'ERROR_ACTIVE_SESSION',
        'ERROR_INVALID_TOKEN',
        'ERROR_500_MSG'
    ]),
    withApollo,
    withRouter,
    withStyles(styles)
)(NewEmailActivationPage);
