import {Question} from './question.class';

export class Questionar {
    questionar_code;
    questionar_description;
    questionar_id;
    questionar_options;
    questionar_recipient;
    questionar_schedule;
    questionar_type;
    questions;
    disabled;

    constructor({
                    questionar_code,
                    questionar_description,
                    questionar_id,
                    questionar_options,
                    questionar_recipient,
                    questionar_schedule,
                    questionar_type,
                    schedule_fulfilled,
                    questions
                }) {
        this.questionar_code = questionar_code;
        this.questionar_description = questionar_description;
        this.questionar_id = questionar_id;
        this.questionar_options = questionar_options;
        this.questionar_recipient = questionar_recipient;
        this.questionar_schedule = questionar_schedule;
        this.questionar_type = questionar_type;
        this.questions = questions.map(q => new Question(q));
    }

    deactivate(value) {
        this.disabled = value;
        this.questions.map(({component: {input}}) => input.disabled = value);
    }
}
