import React, {Component} from 'react';
import {styles} from '../../../styles';
import withStyles from '@material-ui/core/styles/withStyles';
import AppCalendar from '../../ui/Calendar/Calendar';
import DefaultLayout from '../../hoc/layouts/default';
import {compose, graphql} from 'react-apollo';
import DashboardQuery from './patient.query.graphql';
import {CALENDAR_DAY, E_QUESTIONAR, E_VISIT} from '../../../constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import moment from 'moment';
import withApollo from "react-apollo/withApollo";
import QueryTherapyCalendar from "./therapy-assumptions.query.graphql";
import NotificationsService from "../../../services/notifications.service";
import find from "lodash/find";
import AssumptionNotifier from "../../ui/AssumptionNotifier/AssumptionNotifier";
import {fetchPolicy} from "../../../services/offline-manager";

class DashboardComponent extends Component {
    state = {
        selectedDate: new Date(),
        selectedDayEvents: [],
        selectedDayAssumptions: [],
        events: null,
        assumeds: null,
        assumptions: null,
        notify: false,
        selectedAssumption: {}
    };
    calendar: Element;

    onTherapyNotify = (index, assumption_done) => {
        const {selectedDayAssumptions} = this.state;
        let assumption = find(selectedDayAssumptions, {index});
        assumption.status = assumption_done ? 'done' : 'not_done';
        this.setState({
            selectedAssumption: {},
            selectedDayAssumptions: selectedDayAssumptions
        })
    };

    displayEventsDot = (calendarWrapper) => {
        let calendarDays;
        for (let div of calendarWrapper) {
            if (div.getAttribute('aria-hidden') === 'false') {
                calendarDays = div;
            }
        }
        if (calendarDays) {
            calendarDays = calendarDays.getElementsByTagName('button');
            _.forEach(calendarDays, (day) => {
                const dayNumber = day.getElementsByTagName('p');
                if (dayNumber[0]) {
                    const eventDay = _.find(this.state.events, ({schedule_due_date}) => {
                        return moment(schedule_due_date).date() === parseInt(dayNumber[0].innerHTML);
                    });
                    if (eventDay) {
                        if (eventDay.color === 'yellowBackground' || eventDay.color === 'hintBackground' || eventDay.color === 'errorBackground') {
                            day.classList.add('questionar');
                        } else {
                            day.classList.add('visit');
                        }
                    } else {
                        day.classList.remove('visit');
                        day.classList.remove('questionar');
                    }
                }
            });
        }
    };

    onChangeDate = (date) => {
        const {events, assumptions} = this.state;
        date = date ? moment(new Date(date)) : moment();
        this.setState({
                selectedDate: date.toDate()
            },
            () => {
                let selectedDayEvents, selectedDayAssumptions = [];
                try {
                    selectedDayEvents = events.filter(({schedule_due_date}) => {
                        return moment(this.state.selectedDate).isSame(moment(schedule_due_date), 'day');
                    });
                    selectedDayAssumptions = assumptions.filter(({date}) => {
                        return moment(this.state.selectedDate).isSame(moment(date), 'day');
                    });
                } catch (e) {
                    console.error(e);
                }
                this.setState({selectedDayEvents, selectedDayAssumptions});
            }
        );
    };

    onChangeMonth = async ({year, month}) => {
        const {client} = this.props;
        // TODO: debug range queries
        const firstDay = new Date(year, month, 2); // Current first month day
        const lastDay = new Date(year, month + 1, 1); // Current last month day
        try {
            const {data: {TherapyGetCalendar}} = await client.query({
                query: QueryTherapyCalendar,
                variables: {
                    ref_date: firstDay,
                    days: moment(firstDay).diff(moment(lastDay), "days") * -1
                },
                fetchPolicy: fetchPolicy('network-only')
            });
            const {data: {Patient: {schedules, assumeds}}} = await client.query({
                query: DashboardQuery,
                variables: {
                    schedules_filters: [{
                        q: 'schedule_due_date',
                        op: 'dt',
                        values: [firstDay, lastDay]
                    }],
                    assumed_filters: [{
                        q: 'assumed_date',
                        op: 'dt',
                        values: [firstDay, lastDay]
                    }]
                },
                fetchPolicy: fetchPolicy('network-only')
            });
            this.setState({
                events: schedulesToEvents(schedules),
                assumptions: NotificationsService.setAssumptionStatus(TherapyGetCalendar, assumeds)
            });
        } catch (e) {
            console.error(e);
        }
    };

    setEvents = (events) => {
        this.setState({
            events: events
        }, () => {
            let prevDate = localStorage.getItem(CALENDAR_DAY);
            try {
                if (prevDate) {
                    prevDate = JSON.parse(prevDate);
                } else {
                    return this.onChangeDate();
                }
                if (moment(prevDate.savedOn).isSame(moment(), "day")) {
                    this.onChangeDate(prevDate.date);
                } else {
                    this.onChangeDate();
                }
            } catch (e) {
                console.error(e);
                localStorage.removeItem(CALENDAR_DAY);
                this.onChangeDate();
            }

        });
    };

    render() {
        const {classes, loading, title} = this.props;
        const {selectedDate, selectedDayEvents, selectedDayAssumptions, notify, selectedAssumption} = this.state;
        if (loading) {
            return (
                <DefaultLayout title={title}>
                    <Grid container direction={'row'} justify={'center'}>
                        <CircularProgress className={classes.mt4} size={24} color={'primary'}/>
                    </Grid>
                </DefaultLayout>
            )
        }
        const date = this.state.selectedDate || new Date();
        // TODO: debug range queries
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 2); // Current first month day
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1); // Current last month day
        const assumedsQuery = {
            query: DashboardQuery,
            variables: {
                schedules_filters: [{
                    q: 'schedule_due_date',
                    op: 'dt',
                    values: [firstDay, lastDay]
                }],
                assumed_filters: [{
                    q: 'assumed_date',
                    op: 'dt',
                    values: [firstDay, lastDay]
                }]
            }
        };
        return (
            <DefaultLayout title={title}>
                <Grid container justify={'center'}>
                    <Grid item xs={12} md={10} lg={8} xl={6}>
                        <AppCalendar selectedDate={selectedDate}
                                     selectedDayEvents={selectedDayEvents}
                                     selectedDayAssumptions={selectedDayAssumptions}
                                     displayEventsDot={this.displayEventsDot}
                                     onTherapyNotify={(assumption) => this.setState({
                                         notify: true,
                                         selectedAssumption: assumption
                                     })}
                                     onChangeDate={this.onChangeDate} onChangeMonth={this.onChangeMonth}/>
                    </Grid>
                </Grid>
                <AssumptionNotifier open={notify} assumption={selectedAssumption} success={this.onTherapyNotify}
                                    assumedsQuery={assumedsQuery} handleClose={() => this.setState({notify: false})}/>
            </DefaultLayout>
        );
    }

    setResults = (assumptions, assumeds, events) => {
        assumptions = NotificationsService.setAssumptionStatus(assumptions, assumeds);
        assumptions = assumptions.filter(({life}) => life >= 0);
        this.setState({assumptions}, () => this.setEvents(events))
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if ((this.state.events !== nextState.events || this.state.assumptions !== nextState.assumptions) && nextState.events && nextState.assumeds && nextState.assumptions) {
            this.setResults(nextState.assumptions, nextState.assumeds, nextState.events);
        } else if (this.state.events === null && nextProps.events && nextProps.assumeds && this.state.assumptions === null && nextProps.assumptions) {
            this.setResults(nextProps.assumptions, nextProps.assumeds, nextProps.events);
        }
        return true;
    }

    componentDidMount(): void {
        if (this.state.events === null && this.props.events && this.props.assumeds && this.state.assumptions === null && this.props.assumptions) {
            this.setResults(this.props.assumptions, this.props.assumeds, this.props.events);
        }
    }

    componentWillUnmount(): void {
        localStorage.setItem(CALENDAR_DAY, JSON.stringify({
            date: this.state.selectedDate,
            savedOn: moment().toDate()
        }));
    }
}

const schedulesToEvents = (schedules) => {
    const today = moment().format('DD/MM/YYYY');
    schedules = schedules.map((schedule) => {
        const {object: {questionar_code}, object_subtype, schedule_due_date} = schedule;
        const dueDate = moment(schedule_due_date).format('DD/MM/YYYY');
        const isToday = dueDate === today;
        const isPassed = moment(schedule_due_date).isBefore(moment()) && !isToday;
        const event = {
            title: questionar_code,
            isPassed: isPassed,
            isToday: isToday,
            ...schedule
        };
        if (object_subtype === E_VISIT) {
            return {
                color: 'successBackground',
                ...event
            };
        } else if (object_subtype === E_QUESTIONAR) {
            return {
                color: 'yellowBackground',
                ...event
            };
        }
        return {};
    });
    return schedules.filter((schedule) => schedule.color);
};


const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 2); // Current first month day
const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1); // Current last month day

export default compose(
    withApollo,
    graphql(DashboardQuery, {
        props: ({data}) => {
            if (!data.Patient)
                return {
                    loading: data.loading
                };
            let {schedules, assumeds} = data.Patient;
            return {
                assumeds,
                events: schedulesToEvents(schedules),
                dashboardQuery: data
            };
        },
        options: () => ({
                variables: {
                    schedules_filters: [{
                        q: 'schedule_due_date',
                        op: 'dt',
                        values: [firstDay, lastDay]
                    }],
                    assumed_filters: [{
                        q: 'assumed_date',
                        op: 'dt',
                        values: [firstDay, lastDay]
                    }]
                },
                fetchPolicy: fetchPolicy()
            }
        )
    }),
    graphql(QueryTherapyCalendar, {
        props: ({data}) => {
            if (!data.TherapyGetCalendar) {
                return {
                    loading: data.loading
                }
            }
            return {
                loading: data.loading,
                assumptions: data.TherapyGetCalendar
            }
        },
        options: () => {
            return {
                variables: {
                    ref_date: firstDay,
                    days: moment(firstDay).diff(moment(lastDay), "days") * -1
                },
                fetchPolicy: fetchPolicy()
            }
        }
    }),
    withStyles(styles)
)(DashboardComponent);
