import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from '../../../styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

const QRadio = props => {
    const {classes, component, onChange} = props;
    const {input: {radios, title, id, value, disabled}} = component;

    const handleChange = event => {
        onChange(component, event.target.value);
    };
    return (
        <Grid container direction={radios.length > 2 ? 'column' : 'row'} wrap={'wrap'}
              alignItems={radios.length > 2 ? 'flex-start' : 'center'}>
            <Grid item>
                <Grid container direction={'row'} alignItems={'center'}>
                    <Typography variant={'h6'}
                                className={[classes.inputTitleColor, classes.mr1].join(' ')}
                                dangerouslySetInnerHTML={{__html: title}}/>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container className={classes.p1}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row={radios.length <= 2}
                            aria-label={title}
                            name={id}
                            value={value}
                            onChange={handleChange}>
                            {radios.map(
                                ({id, value, label}) => {
                                    return (
                                        <FormControlLabel
                                            key={id}
                                            value={value}
                                            disabled={disabled}
                                            control={<Radio color="primary"/>}
                                            label={label}
                                            labelPlacement={'end'}
                                        />
                                    );
                                }
                            )}
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );
};

QRadio.propTypes = {
    component: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(QRadio);
