import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {styles} from '../../../styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Lock from '@material-ui/icons/Lock';
import Email from '@material-ui/icons/Email';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import RequestEmailMutation from './request-reset-password.graphql';
import SubscribeResetPassword from './subscribe-reset-password.graphql';
import withApollo from 'react-apollo/withApollo';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import {PWA_HOSTING} from '../../../constants';
import {withTranslation} from "../../../i18n/translator.provider";

class ResetPasswordComponent extends Component {
    state = {
        email: '',
        newPassword: '',
        repeatPassword: '',
        token: null,
        toast: false,
        toastMsg: '',
        loading: false
    };

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    getErrorMessage(message) {
        const {translations: {ERROR_ACTIVE_SESSION, ERROR_INVALID_TOKEN}} = this.props;
        switch (message) {
            case 'Action not allowed: scope patient not eq guest':
                return ERROR_ACTIVE_SESSION;
            case 'Missing or Invalid resource: Invite not found':
                return ERROR_INVALID_TOKEN;
            default:
                return 'ERROR_500_MSG';
        }
    }

    submit = (ev) => {
        ev.preventDefault();
        const {email, token, newPassword, repeatPassword} = this.state;
        const {client, translations: {SUCCESS_RESET_PASSWORD, SUCCESS_RESET_PASSWORD_EMAIL, ERROR_500_MSG}} = this.props;
        this.setState({loading: true},
            () => {
                if (token) {
                    client.mutate({
                        mutation: SubscribeResetPassword,
                        variables: {
                            token: token,
                            newpassword: newPassword,
                            newpassword_confirm: repeatPassword
                        },
                        fetchPolicy: 'no-cache'
                    }).then(
                        () => this.setState({
                            loading: false,
                            toast: true,
                            toastMsg: SUCCESS_RESET_PASSWORD
                        })).catch(
                        (data) => this.setState({
                            loading: false,
                            toast: true,
                            toastMsg: data ? data.message : ERROR_500_MSG
                        })
                    );
                } else {
                    client.mutate({
                        mutation: RequestEmailMutation,
                        variables: {
                            url: `${PWA_HOSTING}/reset-password/`,
                            email: email
                        },
                        fetchPolicy: 'no-cache'
                    }).then(
                        () => this.setState({
                            loading: false,
                            toast: true,
                            toastMsg: SUCCESS_RESET_PASSWORD_EMAIL
                        })).catch((data) => this.setState({
                            loading: false,
                            toast: true,
                            toastMsg: data ? data.message : ERROR_500_MSG
                        })
                    );
                }
            });
    };

    render() {
        const {
            classes, translations: {
                LBL_EMAIL,
                LBL_NEW_PASSWORD,
                LBL_REPEAT_PASSWORD,
                TITLE_RESET_PASSWORD_PAGE,
                TXT_INSERT_YOUR_EMAIL,
                TXT_SET_NEW_PASSWORD,
                ERROR_SAME_PASSWORD,
                CTA_REQUEST_PASSWORD,
                CTA_SAVE,
                CTA_BACK_TO_LOGIN
            }
        } = this.props;
        const {email, newPassword, repeatPassword, token, toast, toastMsg, loading} = this.state;
        const inputFields = [
            {
                id: 'email',
                label: LBL_EMAIL,
                type: 'email',
                icon: <Email/>,
                require: !token
            },
            {
                id: 'newPassword',
                label: LBL_NEW_PASSWORD,
                type: 'password',
                icon: <Lock/>,
                require: token
            },
            {
                id: 'repeatPassword',
                label: LBL_REPEAT_PASSWORD,
                type: 'password',
                icon: <Lock/>,
                require: token
            }
        ];
        return (
            <form onSubmit={this.submit} autoComplete={'off'}>
                <Grid container justify={'center'} alignItems={'center'}
                      className={[classes.primaryBackground, classes.mh100].join(' ')}>
                    <Grid item xs={10}>
                        <Grid direction={'column'} justify={'center'} spacing={3} container>
                            <Grid item>
                                <Grid container justify={'center'}>
                                    <img src="/logo-horizontal.svg" alt="HuCare logo" style={{height: '140px'}}/>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction={'column'}>
                                    <Typography variant={'h6'} color={'secondary'}
                                                align={'center'}>{TITLE_RESET_PASSWORD_PAGE}</Typography>
                                    <Typography variant={'subtitle1'} color={'secondary'} align={'center'}>
                                        {!token ? TXT_INSERT_YOUR_EMAIL : TXT_SET_NEW_PASSWORD}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {inputFields.map(input => (
                                input.require &&
                                <Grid item key={input.id}>
                                    <Grid container direction={'row'} justify={'center'}>
                                        <FormControl
                                            className={[classes.w75, classes.secondaryFormControlColor].join(' ')}>
                                            <InputLabel
                                                htmlFor="input-with-icon-adornment">{input.label}</InputLabel>
                                            <Input id={input.id} label={input.label} type={input.type}
                                                   placeholder={input.label}
                                                   onChange={this.handleChange(input.id)}
                                                   autoComplete="false"
                                                   startAdornment={
                                                       <InputAdornment position="start">
                                                           {input.icon}
                                                       </InputAdornment>
                                                   }/>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            ))}
                            {token && <Grid item>
                                <Typography variant={'body2'} color={'secondary'} align={'center'}>
                                    {ERROR_SAME_PASSWORD}
                                </Typography>
                            </Grid>}
                            <Grid item>
                                <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
                                    <Fab color={'secondary'} variant={'extended'} size={'medium'}
                                         className={classes.w75} type={'submit'}
                                         disabled={token ? (!newPassword || !repeatPassword || (newPassword !== repeatPassword)) : !email}>
                                        {loading && <CircularProgress size={24} color={'primary'}/>}
                                        {!loading && (!token ? CTA_REQUEST_PASSWORD : CTA_SAVE)}
                                    </Fab>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Link component={RouterLink} to="/login">
                                    <Typography variant="body1" align={'center'} color={'secondary'}>
                                        {CTA_BACK_TO_LOGIN}
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={toast} onClose={() => {
                    this.setState({toast: false});
                }}
                    message={<span id="message-id">{toastMsg}</span>}/>
            </form>
        );
    }

    componentDidMount(): void {
        this.setState({token: this.props.location.search.split('hucaretoken=')[1]});
    }
}

export default withTranslation([
    'ERROR_ACTIVE_SESSION',
    'ERROR_INVALID_TOKEN',
    'SUCCESS_RESET_PASSWORD',
    'SUCCESS_RESET_PASSWORD_EMAIL',
    'LBL_EMAIL',
    'LBL_NEW_PASSWORD',
    'LBL_REPEAT_PASSWORD',
    'TITLE_RESET_PASSWORD_PAGE',
    'TXT_INSERT_YOUR_EMAIL',
    'TXT_SET_NEW_PASSWORD',
    'ERROR_SAME_PASSWORD',
    'CTA_REQUEST_PASSWORD',
    'CTA_SAVE',
    'CTA_BACK_TO_LOGIN',
    'ERROR_500_MSG'
])(withApollo(withStyles(styles)(withRouter(ResetPasswordComponent))));
