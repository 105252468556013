import React, {useState} from 'react';
import DefaultLayout from "../../hoc/layouts/default";
import SelectIdentityComponent from "../../ui/SelectIdentity/SelectIdentity";
import Grid from "@material-ui/core/Grid";
import User from "../../../services/user.service";
import withApollo from "react-apollo/withApollo";
import ChangeIdentityMutation from "./change-identity.graphql";
import Snackbar from "@material-ui/core/Snackbar";
import {withRouter} from "react-router-dom";

const SelectIdentity = ({history, client}) => {
    const [open, setOpen] = useState(false);
    const [toastMsg, setMessage] = useState('');

    function handleClose() {
        setOpen(false);
    }

    const selectIdentity = async (identity) => {
        try {
            const {data: {ChangeIdentity}} = await client.mutate({
                mutation: ChangeIdentityMutation,
                variables: {
                    key: identity.key
                }
            });
            User.login(ChangeIdentity);
            history.push(`/`);
        } catch (e) {
            setMessage(e.message ? e.message : 'ERROR_500_MSG');
            setOpen(true);
        }
    };
    return (
        <DefaultLayout header={false} sidebar={false} color={'primaryBackground'}>
            <Grid container justify={"center"} alignContent={"flex-start"} style={{height: '100%'}}>
                <Grid item xs={12}>
                    <img src="/logo-horizontal.svg" alt="HuCare logo"
                         style={{margin: '2rem', width: 'auto', maxHeight: '124px'}}/>
                </Grid>
                <Grid item xs={11} md={6} lg={4}>
                    <SelectIdentityComponent onSelect={selectIdentity}/>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={toastMsg}
            />
        </DefaultLayout>
    );
};

export default withApollo(withRouter(SelectIdentity));
