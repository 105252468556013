import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Hidden from '@material-ui/core/Hidden';
import {withRouter} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";

const styles = {
    root: {
        flexGrow: 1,
    },
    headerLogo: {
        flexGrow: 1,
        justifyContent: 'center',
        '& > img': {
            maxHeight: '56px',
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translate(-50%)',
            maxWidth: '50%'
        }
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
};

const AppHeader = (props) => {
    const {classes, title, toggleDrawer, history, match, nCount} = props;
    return (
        <AppBar position={'fixed'} elevation={0}>
            <Toolbar>
                <Hidden mdUp implementation="css">
                    <IconButton className={classes.menuButton} onClick={toggleDrawer} color="inherit"
                                aria-label="Menu">
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
                <div className={classes.headerLogo}>
                    <img src="/logo-horizontal.svg" alt="HuCare logo"/>
                </div>
                <div>
                    {match.path !== '/notifications' &&
                    <Box m={1}>
                        <IconButton aria-label="notifications" color={"secondary"}
                                    onClick={() => history.push('/notifications')}>
                            <Badge badgeContent={nCount} color="error">
                                <NotificationsIcon/>
                            </Badge>
                        </IconButton>
                    </Box>}
                </div>
            </Toolbar>
            <Breadcrumb title={title}/>
        </AppBar>
    );
};

AppHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    nCount: PropTypes.number.isRequired,
    toggleDrawer: PropTypes.func.isRequired
};

export default withRouter(withStyles(styles)(AppHeader));
