import React from 'react';
import PropTypes from 'prop-types';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import User from "../../../services/user.service";
import {withTranslation} from "../../../i18n/translator.provider";

const SelectIdentity = ({onSelect, translations: {TXT_ACCOUNT_SELECT, CTA_NEXT}}) => {
    const [selectedIndex, setSelectedIndex] = React.useState(undefined);
    const identities = User.identities;

    function handleListItemClick(event, index) {
        setSelectedIndex(index);
    }

    return (
        <Card>
            <CardContent>
                <Grid container direction={"column"}>
                    <Grid item>
                        <Typography variant="subtitle1" align={"center"}>{TXT_ACCOUNT_SELECT}</Typography>
                    </Grid>
                    <Grid item>
                        <List component="ul" aria-label="TXT_ACCOUNT_SELECT">
                            {identities.map(({display_name, key}, index) => (
                                <Grid container direction={"column"} key={key}>
                                    <Grid item>
                                        <ListItem component={'li'} button
                                                  selected={selectedIndex === index}
                                                  onClick={event => handleListItemClick(event, index)}>
                                            <ListItemIcon>
                                                <AccountCircleIcon>account_circle</AccountCircleIcon>
                                            </ListItemIcon>
                                            <ListItemText primary={display_name}/>
                                        </ListItem>
                                    </Grid>
                                </Grid>
                            ))}
                        </List>
                    </Grid>

                </Grid>
            </CardContent>
            <CardActions>
                <Grid container justify={"center"}>
                    <Grid item xs md={6}>
                        <Button variant={"outlined"}
                                disabled={selectedIndex === undefined}
                                onClick={() => onSelect(identities[selectedIndex])}
                                fullWidth={true} color={"default"}>{CTA_NEXT}</Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

SelectIdentity.propTypes = {
    onSelect: PropTypes.func.isRequired
};

export default withTranslation([
    'TXT_ACCOUNT_SELECT',
    'CTA_NEXT'
])(SelectIdentity);
