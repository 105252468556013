import {DELETE_THERAPY, SET_NOTIFICATIONS, SET_THERAPIES} from "./actions";

export const notificationInitialState = {
    notifications: null,
    therapies: null
};

export const notificationActions = {
    [SET_NOTIFICATIONS]: (state, newState) => ({
        ...state,
        notifications: newState.notifications,
    }),
    [SET_THERAPIES]: (state, newState) => ({
        ...state,
        therapies: newState.therapies
    }),
    [DELETE_THERAPY]: (state, newState) => {
        return {
            ...state,
            therapies: state.therapies.filter((a) => {
                if (a.assumption_id !== newState.assumption_id) {
                    return true;
                } else {
                    return a.hour !== newState.hour;
                }
            })
        };
    }
};
