import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import DefaultLayout from "../../hoc/layouts/default";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import AssumptionReminder from "../../ui/AssumptionReminder/AssumptionReminder";
import {withRouter} from "react-router";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBack";
import {styles} from "../../../styles";
import withStyles from "@material-ui/styles/withStyles";
import AssumptionNotifier from "../../ui/AssumptionNotifier/AssumptionNotifier";
import {withTranslation} from "../../../i18n/translator.provider";


class Reminder extends Component {
    state = {
        notify: false,
        therapies: [],
        selectedAssumption: {}
    };

    success = (index) => {
        let therapies = this.state.therapies;
        this.setState({therapies: therapies.filter((a) => a.index !== index), selectedAssumption: {}})
    };

    componentDidMount(): void {
        const {location: {state: {therapies = []} = {}}} = this.props;
        this.setState({therapies});
    }

    render() {
        const {notify, selectedAssumption, therapies} = this.state;
        const {title, history, classes, location: {state: {assumedsQuery}}, translations: {TITLE_REMINDER, TXT_NO_ACTION_NEEDED, TXT_LATER}} = this.props;
        return (
            <DefaultLayout title={title} header={true} sidebar={true}>
                <Grid container justify={"center"}>
                    <Grid item xs={12}>
                        <Grid container alignItems={'center'}>
                            <Grid item>
                                <IconButton onClick={() => history.goBack()} color="inherit"
                                            className={classes.hintColor} aria-label="Back">
                                    <BackIcon/>
                                </IconButton>
                            </Grid>
                            <Grid item xs>
                                <Typography variant={"button"} align={"center"} color={"textSecondary"}
                                            style={{margin: '1rem 0 1rem -48px'}} component={'div'}>
                                    {TITLE_REMINDER}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={11} md={10} lg={8}>
                        <Grid container direction={"column"}>
                            <Grid item>
                                <Grid container direction={"column"} spacing={2}>
                                    {therapies.filter(({life}) => life >= 0).map((assumption, index) => (
                                        <Grid item key={index}>
                                            {/*type === 'therapy' && <TherapyReminder reminder={reminder}/>*/}
                                            <AssumptionReminder notify={() => this.setState({
                                                notify: true,
                                                selectedAssumption: assumption
                                            })} assumption={assumption}/>
                                        </Grid>
                                    ))}
                                    {!therapies.length &&
                                    <Grid item style={{marginTop: '2rem'}}>
                                        <Grid container direction={"column"} alignItems={"center"} spacing={2}>
                                            <Grid item>
                                                <Typography variant={"button"} color={"textSecondary"}>
                                                    {TXT_NO_ACTION_NEEDED}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Link component={RouterLink} to={'/calendar'} color={"inherit"}>
                                                    <Typography color={"textSecondary"} variant={"button"}>
                                                        {TXT_LATER}
                                                    </Typography>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <AssumptionNotifier open={notify} assumption={selectedAssumption} success={this.success}
                                    assumedsQuery={assumedsQuery}
                                    handleClose={() => this.setState({notify: false, selectedAssumption: {}})}/>
            </DefaultLayout>
        );
    }
}

Reminder.propTypes = {};

export default withTranslation([
    'TITLE_REMINDER',
    'TXT_NO_ACTION_NEEDED',
    'TXT_LATER',
])(withRouter(withStyles(styles)(Reminder)));
