import React, {Component} from 'react';
import {styles} from '../../../styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import {Calendar} from '../../extra';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Moment from 'react-moment';
import Typography from '@material-ui/core/Typography';
import CalendarEvent from '../CalendarEvent/CalendarEvent';
import moment from "moment";
import ListItem from "@material-ui/core/ListItem";
import orderBy from "lodash/orderBy";
import {withRouter} from "react-router-dom";
import ReportIcon from "@material-ui/icons/Report";
import CheckIcon from "@material-ui/icons/Check";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

class AppCalendar extends Component {

    render() {
        const {classes, selectedDate, selectedDayEvents, selectedDayAssumptions, onChangeDate, onChangeMonth, history} = this.props;
        return (
            <Paper className={[classes.root, classes.m1].join(' ')}>
                <Grid direction={'column'} container>
                    <Grid xs={12} item>
                        <div id={'dashboard-calendar'}
                             className={[classes.calendarBugFix, classes.m2].join(' ')}>
                            <Calendar value={selectedDate} onChange={onChangeDate}
                                      classes={{selectedDay: classes.customSelectedDay}}
                                      onChangeMonth={onChangeMonth}
                                      closeCalendar={() => {
                                      }}
                                      action={() => {
                                      }}/>
                        </div>
                    </Grid>
                    <Grid xs={12} item>
                        <div className={[classes.p3, classes.dividerColor].join(' ')}>
                            <Typography align={'center'} variant={'h6'}
                                        color={'primary'}>
                                <Moment format="ddd DD MMMM YYYY">{selectedDate}</Moment>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid xs={12} item>
                        <div className={classes.p1}>
                            {(!!selectedDayEvents.length || !!selectedDayAssumptions.length) &&
                            <List dense={true}>
                                {selectedDayEvents.map((event, index) => {
                                    return (
                                        <Grid container direction={"column"} key={'sched-' + index}>
                                            <CalendarEvent event={event}/>
                                            {(index !== selectedDayEvents.length - 1 || !!selectedDayAssumptions.length) &&
                                            <Divider className={classes.my1}/>}
                                        </Grid>
                                    );
                                })}
                                {orderBy(selectedDayAssumptions, 'hour').map(
                                    (assumption, index) => {
                                        const {hour, date, medicine_description, medicine_posology, assumption_id, status} = assumption;
                                        let _h;
                                        try {
                                            _h = hour.split(':');
                                        } catch (e) {
                                            console.error(e)
                                        }
                                        const isPassed = moment(date).hours(_h[0]).minutes(_h[1]).isBefore(moment());
                                        return (
                                            <Grid container direction={"column"} key={'ass-' + index}>
                                                <ListItem component={'li'} dense={true} button={isPassed}
                                                          onClick={() => isPassed ? history.push(`therapy-details/${assumption_id}`) : null}>
                                                    <Grid direction={'row'} alignItems={'center'} container>
                                                        <Grid xs item>
                                                            <Grid direction={'row'} alignItems={'center'} container
                                                                  wrap={"nowrap"} spacing={2}>
                                                                <Grid item>
                                                                    <div
                                                                        className={[classes.eventChip, classes.blueChip].join(' ')}/>
                                                                </Grid>
                                                                <Grid item xs style={{overflow: 'hidden'}}>
                                                                    <Grid container style={{overflow: 'hidden'}}>
                                                                        <Grid item container spacing={1} wrap={"nowrap"}
                                                                              style={{overflow: 'hidden'}}>
                                                                            <Grid item>
                                                                                <Typography variant={'subtitle2'}>
                                                                                    {moment(date).hours(hour.split(':')[0]).minutes(hour.split(':')[1]).format('HH:mm')}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                -
                                                                            </Grid>
                                                                            <Grid item xs={9}>
                                                                                <Typography variant={'body2'} noWrap>
                                                                                    {medicine_description}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Typography variant={"caption"}
                                                                                        color={"textSecondary"}
                                                                                        noWrap component={'p'}>
                                                                                {medicine_posology}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Grid container justify={"flex-end"}>
                                                                        {isPassed && status === 'done' &&
                                                                        <CheckIcon
                                                                            className={classes.successColor}/>}
                                                                        {isPassed && status === 'not_done' &&
                                                                        <NotInterestedIcon color={"error"}/>}
                                                                        {isPassed && status === 'close' &&
                                                                        <ReportIcon color={'primary'}/>}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                {index !== selectedDayAssumptions.length - 1 &&
                                                <Divider className={classes.my1}/>}
                                            </Grid>
                                        );
                                    })}
                            </List>}
                            {(!selectedDayEvents.length && !selectedDayAssumptions.length) &&
                            <Typography variant={'body1'} align={'center'}
                                        className={[classes.hintColor, classes.my2].join(' ')}>
                                Nessun evento programmato
                            </Typography>}
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    componentDidMount(): void {
        this.calendarWrapper =
            document.getElementById('dashboard-calendar')
                .getElementsByClassName('react-swipeable-view-container')[0]
                .getElementsByTagName('div');
        this.props.displayEventsDot(this.calendarWrapper);
    }


    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        this.props.displayEventsDot(this.calendarWrapper);
    }
}

AppCalendar.defaultProps = {
    selectedDayEvents: [],
    selectedDayAssumptions: []
};

AppCalendar.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedDate: PropTypes.instanceOf(Date),
    onChangeDate: PropTypes.func.isRequired,
    onChangeMonth: PropTypes.func.isRequired,
    onTherapyNotify: PropTypes.func.isRequired,
    selectedDayEvents: PropTypes.array.isRequired,
    selectedDayAssumptions: PropTypes.array.isRequired
};

export default withStyles(styles)(withRouter(AppCalendar));
