import React from 'react';
import PropTypes from 'prop-types';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import {withTranslation} from "../../../i18n/translator.provider";

const styles = {
    blueCard: {
        borderLeft: `5px solid rgb(13, 136, 241)`
    },
    blueText: {
        color: 'rgb(13, 136, 241)'
    }
};

const AssumptionReminder = props => {
    const {classes, assumption: {medicine_description, medicine_posology, date, hour}, notify, translations: {LBL_TAKE}} = props;
    const h = hour.split(':');
    return (
        <Card className={classes.blueCard}>
            <CardContent>
                <Grid container direction={"column"}>
                    <Grid item>
                        <Grid container alignItems={"center"} spacing={1}>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <NotificationsActiveIcon className={classes.blueText}/>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body1"} color={"primary"} className={classes.blueText}>
                                    {moment(date).hours(h[0]).minutes(h[1]).format('DD MMMM YYYY - HH:mm')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                            {medicine_description}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            {medicine_posology}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Grid container justify={"flex-end"}>
                    {/* <Grid item>
                        <Button color={"inherit"}>
                            <Typography color={"textSecondary"} variant={"button"}>
                                posticipa
                            </Typography>
                        </Button>
                    </Grid>*/}
                    <Grid item>
                        <Button color={"primary"} onClick={() => notify()}>{LBL_TAKE}</Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

AssumptionReminder.propTypes = {
    assumption: PropTypes.object.isRequired,
    notify: PropTypes.func.isRequired
};

export default withTranslation([
    'LBL_TAKE'
])(withStyles(styles)(AssumptionReminder));
