import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {styles} from '../../../styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Lock from '@material-ui/icons/Lock';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import withApollo from 'react-apollo/withApollo';
import SignupMutation from './signup.graphql';
import TokenMutation from './token.graphql';
import User from '../../../services/user.service';
import {COMPLETE_SIGNUP} from '../../../constants';
import Snackbar from '@material-ui/core/Snackbar';
import FingerPrintIcon from "@material-ui/icons/Fingerprint";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withTranslation} from "../../../i18n/translator.provider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Privacy from "../privacy/privacy";

class SignupComponent extends Component {
    state = {
        privacy: false,
        code: '',
        isValidCode: false,
        token: '',
        password: '',
        repeatPassword: '',
        toast: false,
        toastMsg: '',
        loading: false
    };

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    subscribe = async (e) => {
        e.preventDefault();
        const {password, repeatPassword, token} = this.state;
        const {client, location} = this.props;
        this.setState({loading: true});
        try {
            const {data: {Subscribe}} = await client.mutate({
                mutation: SignupMutation,
                variables: {
                    token: token || location.search.split('hucaretoken=')[1],
                    newpassword: password,
                    newpassword_confirm: repeatPassword
                },
                fetchPolicy: 'no-cache'
            });
            this._confirm(Subscribe)
        } catch (e) {
            this._error(e)
        }
    };

    _confirm = (Subscribe) => {
        localStorage.setItem(COMPLETE_SIGNUP, true);
        User.login(Subscribe);
        if (User.identities.length > 1) {
            this.props.history.push(`/select-identity`);
        } else {
            this.props.history.push(`/calendar`);
        }
    };

    _error = (data) => {
        const {translations: {ERROR_INVALID_SUBSCRIPTION, ERROR_500_MSG}} = this.props;
        this.setState({loading: false});
        if (data.message.search('Invite not found') !== -1) {
            this.setState({toast: true, toastMsg: ERROR_INVALID_SUBSCRIPTION});

        } else {
            this.setState({toast: true, toastMsg: ERROR_500_MSG});
        }
    };

    isValidForm = () => {
        const {password, repeatPassword, privacy} = this.state;
        return password && repeatPassword && password === repeatPassword && privacy;
    };

    validToken = async (e) => {
        e.preventDefault();
        const {translations: {SUCCESS_VALID_CODE}} = this.props;
        const {code} = this.state;
        this.setState({loading: true});
        try {
            const {data: {GetSubscribeToken}} = await this.props.client.query({
                query: TokenMutation,
                variables: {
                    shortcode: code
                },
                fetchPolicy: 'network-only'
            });
            this.setState({
                loading: false,
                token: GetSubscribeToken,
                isValidCode: true,
                toast: true,
                toastMsg: SUCCESS_VALID_CODE
            })
        } catch (e) {
            this.setState({
                loading: false,
                toast: true,
                toastMsg: e.message
            })
        }
    };

    handleClose = (privacy) => () => {
        if (privacy) {
            this.setState({privacy: privacy});
        }
        this.setState({dialog: false});
    };

    render() {
        const {
            classes, translations: {
                LBL_NEW_PASSWORD,
                LBL_REPEAT_PASSWORD,
                TXT_SIGNUP_APP_PAYOFF,
                TXT_SET_PASSWORD,
                TXT_SIGNUP_APP_DESC,
                LBL_COD_REG,
                LBL_COD_REG_DESC,
                ERROR_SAME_PASSWORD,
                CTA_VERIFY,
                TXT_SET_YOUR_PASSWORD,
                CTA_BACK_TO_LOGIN,
            }
        } = this.props;
        const {toast, toastMsg, code, isValidCode, password, repeatPassword, loading, dialog, privacy} = this.state;
        const inputFields = [
            {
                id: 'password',
                label: LBL_NEW_PASSWORD,
                type: 'password',
                value: password,
                icon: <Lock/>
            },
            {
                id: 'repeatPassword',
                label: LBL_REPEAT_PASSWORD,
                type: 'password',
                value: repeatPassword,
                icon: <Lock/>
            }];
        return (
            <form onSubmit={isValidCode ? this.subscribe : this.validToken}>
                <Grid container justify={'center'} alignItems={'center'}
                      className={[classes.primaryBackground, classes.mh100].join(' ')}>
                    <Grid item xs={10}>
                        <Grid direction={'column'} justify={'center'} spacing={3} container>
                            <Grid item>
                                <Grid container justify={'center'}>
                                    <img src="/logo-horizontal.svg" alt="HuCare logo" style={{height: '140px'}}/>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction={'column'}>
                                    <Typography variant={'h6'} color={'secondary'} align={'center'}>
                                        {TXT_SIGNUP_APP_PAYOFF}
                                    </Typography>
                                    <Typography variant={'subtitle1'} color={'secondary'} align={'center'}>
                                        {isValidCode ? TXT_SET_PASSWORD : TXT_SIGNUP_APP_DESC}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {!isValidCode &&
                            <Grid item xs={12} container justify={'center'}>
                                <FormControl
                                    className={[classes.w75, classes.secondaryFormControlColor].join(' ')}>
                                    <InputLabel
                                        htmlFor="input-with-icon-adornment">{LBL_COD_REG}</InputLabel>
                                    <Input placeholder={LBL_COD_REG_DESC}
                                           value={code} autoFocus
                                           onChange={(e) => this.setState({code: e.target.value.toUpperCase()})}
                                           startAdornment={
                                               <InputAdornment position="start">
                                                   <FingerPrintIcon/>
                                               </InputAdornment>
                                           }/>
                                </FormControl>
                            </Grid>}
                            {isValidCode && inputFields.map(input => (
                                <Grid item xs={12} key={input.id} container justify={'center'}>
                                    <FormControl
                                        className={[classes.w75, classes.secondaryFormControlColor].join(' ')}>
                                        <InputLabel
                                            htmlFor="input-with-icon-adornment">{input.label}</InputLabel>
                                        <Input id={input.id} label={input.label} type={input.type}
                                               placeholder={input.label} autoComplete={'new-password'}
                                               value={input.value} onChange={this.handleChange(input.id)}
                                               startAdornment={
                                                   <InputAdornment position="start">
                                                       {input.icon}
                                                   </InputAdornment>
                                               }/>
                                    </FormControl>
                                </Grid>
                            ))}
                            <Grid item>
                                {password !== repeatPassword &&
                                <Typography variant={'body2'} color={'error'} align={'center'}>
                                    {ERROR_SAME_PASSWORD}
                                </Typography>}
                            </Grid>
                            {isValidCode &&
                            //YEDML ZM7XW
                            <Grid item xs={12} container justify={"center"}>
                                <FormControlLabel
                                    className={[classes.secondaryFormControlColor, classes.w75].join(' ')}
                                    style={{display: 'flex', alignItems: 'flex-start'}}
                                    control={
                                        <Checkbox
                                            checked={privacy} style={{paddingTop: 0}}
                                            onChange={(e) => this.setState({privacy: e.target.checked})}
                                            value="privacy" className={classes.secondaryFormControlColor}/>}

                                    label={
                                        <Typography variant={"caption"} align={"left"} component={'p'}>
                                            Confermo di aver preso visione dell'informativa sulla Privacy e dei termini
                                            e condizioni di utilizzo di HuCare Trial
                                        </Typography>
                                    }
                                />
                            </Grid>}
                            <Grid item>
                                <Grid container alignItems={'center'} justify={'center'} spacing={2}>
                                    {!isValidCode &&
                                    <Grid item xs={12}>
                                        <Fab color={'secondary'} variant={'extended'} size={'medium'} type="submit"
                                             disabled={!code} style={{width: '80%', margin: '0 10%'}}>
                                            {!loading && CTA_VERIFY}
                                            {loading && <CircularProgress size={24}/>}
                                        </Fab>
                                    </Grid>}
                                    {isValidCode &&
                                    <Grid item xs={12}>
                                        <Button color={'secondary'} size={'small'} variant={"outlined"}
                                                onClick={()=> this.setState({dialog: true})}
                                                style={{width: '80%', margin: '0 10%'}}>
                                            leggi i termini e le condizioni
                                        </Button>
                                    </Grid>}
                                    {isValidCode &&
                                    <Grid item xs={12}>
                                        <Fab color={'secondary'} variant={'extended'} size={'medium'}
                                             disabled={!this.isValidForm()} style={{width: '80%', margin: '0 10%'}}
                                             type="submit">
                                            {!loading && TXT_SET_YOUR_PASSWORD}
                                            {loading && <CircularProgress size={24}/>}
                                        </Fab>
                                    </Grid>}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Link component={RouterLink} to="/login">
                                    <Typography variant="body1" color={'secondary'} align={'center'}>
                                        {CTA_BACK_TO_LOGIN}
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={toast}
                        autoHideDuration={6000}
                        onClose={() => {
                            this.setState({toast: false});
                        }}
                        message={<span id="message-id">{toastMsg}</span>}/>
                        <Privacy dialog={dialog} handleClose={this.handleClose}/>
                </Grid>
            </form>
        );
    }

    componentDidMount(): void {
        const token = this.props.location.search.split('hucaretoken=')[1];
        if (token) {
            this.setState({isValidCode: true, token})
        }
    }
}

export default withTranslation([
    'LBL_NEW_PASSWORD',
    'LBL_REPEAT_PASSWORD',
    'TXT_SIGNUP_APP_PAYOFF',
    'TXT_SET_PASSWORD',
    'TXT_SIGNUP_APP_DESC',
    'LBL_COD_REG',
    'LBL_COD_REG_DESC',
    'ERROR_SAME_PASSWORD',
    'CTA_VERIFY',
    'TXT_SET_YOUR_PASSWORD',
    'CTA_BACK_TO_LOGIN',
    'ERROR_INVALID_SUBSCRIPTION',
    'ERROR_500_MSG',
    'SUCCESS_VALID_CODE',
])(withApollo(withStyles(styles)(withRouter(SignupComponent))));
