import React from 'react';
import {styles} from '../../../styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import withRouter from 'react-router/withRouter';
import {withTranslation} from "../../../i18n/translator.provider";
import {E_QUESTIONAR} from "../../../constants";

const AppNotification = ({classes, history, notification: {color, icon, schedule_id, object_subtype, object_id, object: {questionar_description}, schedule_fulfilled, schedule_due_date, isPassed}, translations: {TXT_COMPILE_QUESTIONAR, TXT_GET_READY_TO_VISIT, LBL_QUESTIONAR}}) => {
    const notificationCTA = () => {
        if (object_subtype === E_QUESTIONAR)
            history.push(`/questionar/`, {
                    object_id,
                    schedule_id,
                    schedule_fulfilled,
                    schedule_due_date,
                    isPassed
                }
            );
    };
    return (
        <Paper className={[classes.root, classes.m1, classes.p1, classes.topNotification, classes[color]].join(' ')}
               onClick={notificationCTA} elevation={1}>
            <Grid spacing={2} direction={'row'} alignItems={'center'} justify={'flex-start'} container>
                <Grid item>
                    {icon}
                </Grid>
                <Grid xs item>
                    <Typography className={color}
                                variant={"subtitle2"}>{object_subtype === E_QUESTIONAR ? TXT_COMPILE_QUESTIONAR : TXT_GET_READY_TO_VISIT}</Typography>
                    <Typography color={'textSecondary'} variant={'caption'}>{object_subtype === E_QUESTIONAR ? LBL_QUESTIONAR : questionar_description}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

AppNotification.propTypes = {
    classes: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired
};

export default withTranslation([
    'LBL_QUESTIONAR',
    'TXT_GET_READY_TO_VISIT',
    'TXT_COMPILE_QUESTIONAR'
])(withRouter(withStyles(styles)(AppNotification)));
