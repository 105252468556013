import {
    ALLOW_MOBILE_WEBSITE,
    AUTH_TOKEN,
    COMPLETE_SIGNUP,
    EMAIL,
    HU_LOCALE,
    HU_TIMEZONE,
    IDENTITIES,
    USERNAME
} from '../constants';

class User {

    static login({token, code, locale, timezone, identities, email}) {
        User.code = code;
        User.identities = identities;
        User.token = token;
        if (email) {
            User.email = email;
        }
        User.locale = locale;
        User.timezone = timezone;
        //clear cache
        localStorage.removeItem('apollo-cache-persist');
    }

    static logOut = async (client) => {
        localStorage.clear();
        localStorage.setItem(COMPLETE_SIGNUP, true);
        localStorage.setItem(ALLOW_MOBILE_WEBSITE, true);
        await client.clearStore();
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations()
                .then((registrations) => {
                    for (let registration of registrations) {
                        registration.unregister();
                    }
                });
        }
    };

    static isAuth = () => {
        return !!localStorage.getItem(AUTH_TOKEN);
    };

    static get email() {
        return localStorage.getItem(EMAIL);
    }

    static set email(email) {
        localStorage.setItem(EMAIL, email);
    }

    static get code() {
        return localStorage.getItem(USERNAME);
    }

    static get token() {
        return localStorage.getItem(AUTH_TOKEN);
    }

    static get identities() {
        try {
            const identities = localStorage.getItem(IDENTITIES);
            return JSON.parse(identities) || [];
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    static set code(code) {
        localStorage.setItem(USERNAME, code);
    }

    static set token(token) {
        localStorage.setItem(AUTH_TOKEN, token);
    }

    static set identities(identities) {
        localStorage.setItem(IDENTITIES, JSON.stringify(identities));
    }

    static get currentIdentity() {
        return User.identities.find((identity) => identity.code === User.code) || {};
    };

    static get locale() {
        return localStorage.getItem(HU_LOCALE);
    }

    static set locale(locale) {
        localStorage.setItem(HU_LOCALE, locale);
    }

    static get timezone() {
        return localStorage.getItem(HU_TIMEZONE);
    }

    static set timezone(timezone) {
        localStorage.setItem(HU_TIMEZONE, timezone);
    }
}

export default User;
