import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {E_QUESTIONAR, E_VISIT} from '../../../constants';
import {Link as RouteLink} from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from '../../../styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from "../../../i18n/translator.provider";

class CalendarEvent extends Component {
    displayEvent = ({color, schedule_fulfilled, object: {questionar_description}, isPassed, object_subtype}) => {
        const {classes, translations: {LBL_QUESTIONAR}} = this.props;
        return (
            <Grid direction={'row'} alignItems={'center'} container>
                <Grid xs item>
                    <Grid direction={'row'} alignItems={'center'} wrap={'nowrap'} spacing={2} container>
                        <Grid item>
                            <div
                                className={[classes.eventChip, classes[color]].join(' ')}/>
                        </Grid>
                        <Grid item xs>
                            <Typography
                                variant={'subtitle2'}>{object_subtype === E_VISIT ? questionar_description : LBL_QUESTIONAR}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid direction={'row'} alignItems={'center'} justify={'flex-end'} wrap={'nowrap'} container>
                        {schedule_fulfilled ?
                            <CheckIcon className={classes.successColor}/> :
                            (isPassed && <NotInterestedIcon color={"error"}/>)
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    render() {
        const {event} = this.props;
        const {object_subtype, object_id, schedule_id, schedule_fulfilled, schedule_due_date, isPassed} = event;
        if (object_subtype === E_QUESTIONAR && isPassed) {
            return (
                <ListItem button component={RouteLink}
                          to={{
                              pathname: `/questionar/`,
                              state: {
                                  object_id: object_id,
                                  schedule_id: schedule_id,
                                  schedule_fulfilled: schedule_fulfilled,
                                  schedule_due_date: schedule_due_date,
                                  isPassed: isPassed
                              }
                          }}>
                    <ListItemText primary={this.displayEvent(event)}/>
                </ListItem>
            )
        }
        return (
            <ListItem>
                <ListItemText primary={this.displayEvent(event)}/>
            </ListItem>
        );
    }
}

CalendarEvent.propTypes = {
    classes: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired
};

export default withTranslation([
    'LBL_QUESTIONAR'
])(withStyles(styles)(CalendarEvent));
