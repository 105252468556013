import React from 'react';
import DefaultLayout from "../../hoc/layouts/default";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CheckIcon from "@material-ui/icons/Check";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import BackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import {withRouter} from "react-router";
import TherapyDetailsQuery from "./patient.query.graphql";
import {graphql} from "react-apollo";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import orderBy from "lodash/orderBy";
import {fetchPolicy} from "../../../services/offline-manager";
import {withTranslation} from "../../../i18n/translator.provider";

const styles = {
    blueCard: {
        borderLeft: `5px solid rgb(13, 136, 241)`,
        padding: '1rem'
    },
    blueText: {
        color: 'rgb(13, 136, 241)'
    }
};

const TherapyDetails = props => {
    const {
        title, classes, history, loading, assumeds = [],
        translations: {
            LBL_MEDICINE_DETAIL,
            TXT_DAILY,
            TXT_EACH_DAY,
            LBL_HISTORY,
            LBL_ASSUMPTION,
            LBL_STATUS,
            TXT_PLANNED_AT,
            TXT_NO_HISTORY,
            TXT_ONE_TIME,
            LBL_HOUR,
            LBL_POSOLOGY,
            TXT_MANY_TIMES,
        },
        assumption: {
            medicine: {medicine_name, medicine_dosage, medicine_principle} = {}, assumption_posology = [],
            assumption_every, assumption_weekday = []
        } = {}
    } = props;
    const getDays = (weekday) => {
        const days = [];
        weekday.forEach((day) => {
            switch (parseInt(day)) {
                case 1:
                    return days.push('lun');
                case 2:
                    return days.push(' mar');
                case 3:
                    return days.push(' mer');
                case 4:
                    return days.push(' giov');
                case 5:
                    return days.push(' ven');
                case 6:
                    return days.push(' sab');
                case 7:
                    return days.push(' dom');
                default:
                    return days.push('');
            }
        });
        return days.join(',');
    };
    return (
        <DefaultLayout title={title}>
            <Grid container justify={"center"} style={{marginTop: '2rem'}}>
                <Grid item xs={11} md={10} lg={8}>
                    <Grid container direction={"column"} spacing={3}>
                        <Grid item container alignItems={"center"}>
                            <Grid item>
                                <IconButton className={classes.hintColor}
                                            onClick={() => history.goBack()}
                                            color="default" aria-label="Back">
                                    <BackIcon/>
                                </IconButton>
                            </Grid>
                            <Grid item xs>
                                <Typography variant={"button"} color={"textSecondary"}
                                            component={'div'} align={"center"} style={{marginLeft: '-48px'}}>
                                    {LBL_MEDICINE_DETAIL}
                                </Typography>
                            </Grid>
                        </Grid>
                        {loading &&
                        <Grid item container justify={"center"}>
                            <CircularProgress size={24}/>
                        </Grid>}
                        {!loading &&
                        <Grid item>
                            <Paper className={classes.blueCard}>
                                <Grid container direction={"column"} spacing={1}>
                                    <Grid item>
                                        <Typography variant={"subtitle2"} className={classes.blueText}>
                                            {medicine_name} {medicine_dosage}
                                        </Typography>
                                        <Typography variant={"body2"} className={classes.blueText}
                                                    style={{opacity: '.5'}}>
                                            {medicine_principle}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                                            {assumption_posology.length} {assumption_posology.length === 1 ? TXT_ONE_TIME : TXT_MANY_TIMES} {TXT_DAILY} {assumption_every === 1 ? getDays(assumption_weekday) : TXT_EACH_DAY}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{LBL_HOUR}</TableCell>
                                                    <TableCell>{LBL_POSOLOGY}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orderBy(assumption_posology, 'hour').map(({hour, notes}, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell style={{verticalAlign: 'text-top'}}>
                                                            <Typography variant={"subtitle2"}>
                                                                {moment().hours(hour.split(':')[0]).minutes(hour.split(':')[1]).format('HH:mm')}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell style={{verticalAlign: 'text-top'}}>
                                                            <Typography variant={"body1"} color={"textSecondary"}>
                                                                {notes}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>}
                        <Grid item>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant={"button"} color={"textSecondary"}
                                                component={'div'} align={"center"}>
                                        {LBL_HISTORY}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Paper>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {LBL_ASSUMPTION}
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                {LBL_STATUS}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loading &&
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <Grid item container justify={"center"}>
                                                    <CircularProgress size={24}/>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>}
                                        {!loading && !assumeds.length &&
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <Typography variant={"body2"} color={"textSecondary"} align={"center"}>
                                                    {TXT_NO_HISTORY}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>}
                                    </TableBody>
                                    {assumeds.map(
                                        ({assumed_date, assumed_hour, assumed_done, created_at}, index) => {
                                            let hour = ['00', '00'];
                                            try {
                                                hour = assumed_hour.split(':');
                                            } catch (e) {
                                                console.error(e);
                                            }
                                            return (
                                                <TableBody key={`assumed-${index}`}>
                                                    {((index === 0 || assumeds[index - 1].assumed_date !== assumed_date)) &&
                                                    <TableRow>
                                                        <TableCell colSpan={2} align={"center"}
                                                                   style={{backgroundColor: '#f3f3f3'}}>
                                                            <Typography variant={"button"}>
                                                                {moment(assumed_date).format('DD MMMM YYYY')}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>}
                                                    <TableRow>
                                                        <TableCell align={"left"}>
                                                            <Grid container direction={"column"}>
                                                                <Grid item>
                                                                    <Typography variant={"subtitle2"}>
                                                                        {moment(created_at).format('HH:mm')}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant={"caption"}
                                                                                color={"textSecondary"}>
                                                                        {TXT_PLANNED_AT} {moment().hours(hour[0]).minutes(hour[1]).format('HH:mm')}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell align={"right"}>
                                                            {assumed_done ? <CheckIcon style={{color: '77c97d'}}/> :
                                                                <NotInterestedIcon color={"error"}/>}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            )
                                        })}
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DefaultLayout>
    );
};

TherapyDetails.propTypes = {};

export default withRouter(
    graphql(TherapyDetailsQuery, {
            props: ({data}) => {
                if (!data.Patient) {
                    return {
                        loading: data.loading
                    }
                }
                return {
                    loading: data.loading,
                    assumeds: orderBy(data.Patient.assumeds, ['assumed_date', 'assumed_hour'], 'desc'),
                    assumption: data.Patient.assumptions[0]
                }
            },
            options: ({match: {params: {id}}}) => {
                return {
                    variables: {
                        assumed_filters: [{
                            q: 'assumption_id',
                            op: 'eq',
                            value: id
                        }],
                        assumptions_filters: [{
                            q: 'assumption_id',
                            op: 'eq',
                            value: id
                        }]
                    },
                    fetchPolicy: fetchPolicy()
                }
            },
            skip: ({match: {params: {id}}}) => !id
        }
    )(withTranslation([
        'LBL_MEDICINE_DETAIL',
        'TXT_DAILY',
        'LBL_HOUR',
        'LBL_POSOLOGY',
        'TXT_EACH_DAY',
        'LBL_HISTORY',
        'LBL_ASSUMPTION',
        'LBL_STATUS',
        'TXT_PLANNED_AT',
        'TXT_NO_HISTORY',
        'TXT_ONE_TIME',
        'TXT_MANY_TIMES',
    ])(withStyles(styles)(TherapyDetails))));
