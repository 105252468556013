import React, {Component} from 'react';
import translations from './index';
import moment from "moment";
import {HU_LOCALE} from "../constants";
import pick from "lodash/pick";
import User from "../services/user.service";
import 'moment/locale/it';

const TranslatorCxt = React.createContext(null);

export const getLang = () => {
    let locale = localStorage.getItem(HU_LOCALE);
    try {
        locale = locale.split('_')[0];
        if (locale.length === 2) {
            return locale;
        }
        return navigator.language ? navigator.language.split('-')[0] : 'en';
    } catch (e) {
        return navigator.language ? navigator.language.split('-')[0] : 'en';
    }
};

export const getLabel = (key) => {
    return translations[getLang()][key];
};

export class TranslatorProvider extends Component {
    //qui setti la default lang
    state = {
        lang: getLang(),
        translations
    };

    setLang = lang => {
        try {
            if (lang.length === 2) {
                this.setState({lang});
            } else {
                User.locale = lang;
                lang = lang.split('_')[0];
                if (lang.length === 2) {
                    this.setState({lang});
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        moment.locale(this.state.lang);
        return (
            <TranslatorCxt.Provider
                value={{
                    lang: this.state.lang,
                    translations: this.state.translations,
                    setLang: this.setLang
                }}>
                {this.props.children}
            </TranslatorCxt.Provider>
        );
    }
}

export const TranslatorManager = TranslatorCxt.Consumer;

export const withTranslation = (keysToTranslate) => (WrappedComponent) => {
    return (props) => (
        <TranslatorManager>
            {({lang, translations, setLang}) => {
                translations = translations[lang];
                if (keysToTranslate && keysToTranslate.length) {
                    translations = pick(translations, keysToTranslate);
                }
                return (
                    <WrappedComponent lang={lang}
                                      translations={translations}
                                      setLang={setLang} {...props}/>
                );
            }}
        </TranslatorManager>
    )
};

