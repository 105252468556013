import React from 'react';
import {styles} from '../../../styles';
import withStyles from '@material-ui/core/styles/withStyles';
import DefaultLayout from '../../hoc/layouts/default';
import VisitHistory from '../../ui/VisitHistory/VisitHistory';
import {E_VISIT} from '../../../constants';
import {compose, graphql} from 'react-apollo';
import VisitsQuery from './visits.graphql';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import _ from 'lodash';
import {fetchPolicy} from "../../../services/offline-manager";
import {withTranslation} from "../../../i18n/translator.provider";


const VisitsComponent = ({title, loading, visits, classes, translations: {TITLE_VISITS_HISTORY}}) => {
    return (
        <DefaultLayout title={title}>
            <Grid container justify={'center'}>
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Grid container direction={'column'}>
                        <Typography align={'center'} variant={'h6'}
                                    className={[classes.hintColor, classes.my1].join(' ')}>
                            {TITLE_VISITS_HISTORY}
                        </Typography>
                        <Grid item xs={12}>
                            <Grid container direction={'row'} justify={'center'}>
                                {loading && <CircularProgress size={28} className={classes.mt4}/>}
                                {!loading && <VisitHistory visits={visits}/>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DefaultLayout>
    );
};

const mapResultsToProps = ({data}) => {
    if (!data.Patient)
        return {
            loading: data.loading,
        };
    let {schedules} = data.Patient;
    const today = moment().format('DD/MM/YYYY');
    schedules = schedules.map((schedule) => {
        const {object: {questionar_code}, schedule_due_date} = schedule;
        const dueDate = moment(schedule_due_date).format('DD/MM/YYYY');
        const isToday = dueDate === today;
        const isPassed = moment(schedule_due_date).isBefore(moment()) && !isToday;
        return {
            title: questionar_code,
            isToday: isToday,
            isPassed: isPassed,
            ...schedule
        };
    });
    if (!schedules.length) {
        return {
            visits: []
        };
    }
    return {
        visits: _.orderBy(schedules, ['schedule_due_date'], 'desc')
    };
};

const mapPropsToOptions = () => {
    return {
        variables: {
            schedules_filters: [
                {
                    q: 'object_subtype',
                    op: 'eq',
                    value: E_VISIT
                },
                {
                    "q": "schedule_due_date",
                    "op": "le",
                    "value": moment().format('YYYY-MM-DD')
                }
            ]
        },
        fetchPolicy: fetchPolicy()
    };
};

export default compose(
    graphql(VisitsQuery, {
        props: mapResultsToProps,
        options: mapPropsToOptions
    }),
    withTranslation([
        'TITLE_VISITS_HISTORY'
    ]),
    withStyles(styles)
)(VisitsComponent);
