import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withApollo from "react-apollo/withApollo";
import Dialog from "@material-ui/core/Dialog";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import DoneIcon from "@material-ui/icons/Done";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import TherapyNotifyMutation from "./therapy-notify.mutation.graphql";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getPending, setPending, updateAssumptionState} from "../../../services/offline-mutations.service";
import User from "../../../services/user.service";
import {compose} from "react-apollo";
import {withTranslation} from "../../../i18n/translator.provider";
import {withStyles} from "@material-ui/styles";
import {styles} from "../../../styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {WatchLater} from '@material-ui/icons';

class AssumptionNotifier extends Component {
    state = {
        assumption_done: undefined,
        assumption_later: undefined,
        snackbar: false,
        snackMsg: null,
        loading: false
    };

    save = async () => {
        const {client, assumption: {index, medicine_id, medicine_description, assumption_id, hour, date}} = this.props;
        if (this.state.assumption_later) {
            return this.onClose();
        }
        this.setState({loading: true});
        try {
            const {key} = User.currentIdentity;
            const mutationId = `mutation-${key}-${assumption_id}`;
            // REGISTRY THE MUTATION IN THE CACHE
            const assumptionMutation = {
                mutation: TherapyNotifyMutation,
                variables: {
                    assumed: [{
                        medicine_id,
                        assumption_id,
                        assumed_hour: hour,
                        assumed_date: date,
                        assumed_done: this.state.assumption_done

                    }]
                },
                optimisticResponse: {
                    TherapyNotifyAssumed: {
                        assumed_id: moment().valueOf(),
                        assumed_date: date,
                        assumed_hour: hour,
                        assumption_id: assumption_id,
                        assumed_done: this.state.assumption_done,
                        medicine: {
                            medicine_id: medicine_id,
                            medicine_name: medicine_description,
                            medicine_principle: '',
                            medicine_dosage: '',
                            __typename: 'Medicine'

                        },
                        created_at: moment().toDate(),
                        updated_at: moment().toDate(),
                        __typename: 'TherapyNotifyAssumed'
                    },
                    __optimistic: true
                },
                update: updateAssumptionState(assumption_id, this.props.assumedsQuery, () => this.success(index))
            };
            setPending(getPending().concat({
                id: mutationId,
                params: assumptionMutation,
                mutation: TherapyNotifyMutation
            }));
            await client.mutate(assumptionMutation);
            this.success(index);
        } catch (e) {
            if (e.message !== 'Network error: Failed to fetch') {
                this.setState({
                    loading: false,
                    snackbar: true,
                    snackMsg: e.message
                });
            } else {
                this.success(index);
            }
        }
    };

    success = (index) => {
        this.props.success(index, this.state.assumption_done);
        this.setState({
            loading: false,
            snackbar: true,
            snackMsg: 'Assunzione salvata correttamente',
            assumption_done: undefined
        });
        this.props.handleClose();
    };
    onClose = () => this.setState({assumption_done: undefined}, () => this.props.handleClose());

    render() {
        const {
            open, handleClose, assumption: {medicine_description, medicine_posology, hour, date}, translations: {
                LBL_NOT_TAKEN,
                LBL_TAKEN,
                LBL_GET_LATER,
                CTA_SAVE
            },
            classes
        } = this.props;
        const {snackbar, snackMsg, loading, assumption_done, assumption_later} = this.state;
        let _hour = ['00', '00'];
        try {
            _hour = hour ? hour.split(':') : _hour;
        } catch (e) {
            console.error(e);
        }
        return (
            <React.Fragment>
                <Dialog
                    maxWidth={"md"} fullWidth open={open} scroll={"body"}
                    onClose={handleClose}>
                    <DialogTitle disableTypography style={{padding: 0}}>
                        <Grid container justify={"flex-end"}>
                            <Grid item>
                                <IconButton aria-label="close" onClick={this.onClose}>
                                    <CloseIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction={"column"} alignItems={"center"} spacing={2}>
                            <Grid item container justify={"center"} spacing={1} alignItems={"center"}>
                                <Grid item>
                                    <AccessTimeIcon className={classes.blueColor}/>
                                </Grid>
                                <Typography className={classes.blueColor} variant={"body1"}>
                                    {moment(date).hours(_hour[0]).minutes(_hour[1]).format('HH:mm A')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body1"} align={"center"}>
                                    {medicine_description}
                                </Typography>
                                {/*<Typography component={'p'} color={"textSecondary"} variant={"caption"}
                                            align={"center"}>
                                    ({medicine_principle} - {medicine_dosage})
                                </Typography>*/}
                            </Grid>
                            <Grid item>
                                <Typography variant={"body1"} color={"textSecondary"}>
                                    {medicine_posology}
                                </Typography>
                            </Grid>
                            <Grid item container spacing={2} justify={"center"}>
                                <Grid item xs={12} md={4} lg={3}>
                                    <Button
                                        className={assumption_done === false ? classes.errorBtn : classes.errorBtnOutline}
                                        fullWidth
                                        onClick={() => this.setState({assumption_done: false, assumption_later: false})}
                                        variant={assumption_done === false ? "contained" : 'outlined'}>
                                        <Grid container justify={"center"} alignItems={"center"} spacing={1}>
                                            <Grid item style={{display: 'block'}}>
                                                <NotInterestedIcon style={{display: 'block', margin: 'auto'}}/>
                                            </Grid>
                                            <Grid item>
                                                {LBL_NOT_TAKEN}
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={4} lg={3}>
                                    <Button
                                        color={'default'}
                                        fullWidth onClick={() => this.setState({
                                        assumption_done: undefined,
                                        assumption_later: true
                                    })}
                                        variant={assumption_later === true ? "contained" : 'outlined'}>
                                        <Grid container justify={"center"} alignItems={"center"} spacing={1}>
                                            <Grid item style={{display: 'block'}}>
                                                <WatchLater style={{display: 'block', margin: 'auto'}}/>
                                            </Grid>
                                            <Grid item>
                                                {LBL_GET_LATER}
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={4} lg={3}>
                                    <Button
                                        className={assumption_done === true ? classes.successBtn : classes.successBtnOutline}
                                        fullWidth
                                        onClick={() => this.setState({assumption_done: true, assumption_later: false})}
                                        variant={assumption_done === true ? "contained" : 'outlined'}>
                                        <Grid container justify={"center"} alignItems={"center"} spacing={1}>
                                            <Grid item style={{display: 'block'}}>
                                                <DoneIcon style={{display: 'block', margin: 'auto'}}/>
                                            </Grid>
                                            <Grid item>
                                                {LBL_TAKEN}
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.save} color="primary"
                                disabled={assumption_done === undefined && assumption_later === false} autoFocus>
                            {!loading && CTA_SAVE}
                            {loading && <CircularProgress size={24}/>}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    autoHideDuration={6000}
                    onClose={() => this.setState({snackbar: false})}
                    message={snackMsg}
                    open={snackbar}>
                </Snackbar>
            </React.Fragment>
        );
    }
}

AssumptionNotifier.defaultProps = {
    assumption: {}
};

AssumptionNotifier.propTypes = {
    assumedsQuery: PropTypes.object.isRequired,
    assumption: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    success: PropTypes.func.isRequired
};

export default compose(
    withTranslation([
        'LBL_NOT_TAKEN',
        'LBL_TAKEN',
        'LBL_GET_LATER',
        'CTA_SAVE'
    ]),
    withApollo,
    withStyles(styles)
)(AssumptionNotifier);
