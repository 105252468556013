import moment from "moment";
import {E_QUESTIONAR, E_VISIT} from "../constants";
import DescriptionIcon from '@material-ui/icons/Description';
import EventIcon from '@material-ui/icons/Event';
import React from "react";

class NotificationsService {
    static getFutureAssumptions = (assumptions) => {
        return assumptions.filter(({date, hour}) => {
            const _h = hour.split(':');
            const assumptionDateTime = moment(date).hours(_h[0]).minutes(_h[1]);
            return assumptionDateTime.isAfter(moment());
        }).map((assumption, index) => ({
            ...assumption,
            index
        }));
    };

    static getOldAssumptions = (assumptions, assumeds) => {
        const old = assumptions.filter(({date, hour}) => {
            try {
                const _h = hour.split(':');
                const assumptionDateTime = moment(date).hours(_h[0]).minutes(_h[1]);
                return assumptionDateTime.isSameOrBefore(moment(), "minutes") && assumptionDateTime.isSame(moment(), "day");
            } catch (e) {
                console.error(e);
                return false;
            }
        });
        return NotificationsService.setAssumptionStatus(old, assumeds);
    };

    static getTodayAssumptions = (assumptions, assumeds) => {
        const old = assumptions.filter(({date, hour}) => {
            try {
                const _h = hour.split(':');
                const assumptionDateTime = moment(date).hours(_h[0]).minutes(_h[1]);
                return assumptionDateTime.isSame(moment(), "day");
            } catch (e) {
                console.error(e);
                return false;
            }
        });
        return NotificationsService.setAssumptionStatus(old, assumeds, true);
    };

    static setAssumptionStatus = (assumptions, assumeds, allDay) => {
        return assumptions.map((assumption, index) => {
                const {assumption_id, date, hour} = assumption;
                const hours = hour.split(':');
                const assumptionDateTime = moment(date).hours(hours[0]).minutes(hours[1]);
                const isOpen = allDay ? assumptionDateTime.isSame(moment(), "day") : moment().isAfter(assumptionDateTime) && assumptionDateTime.isSame(moment(), "day");
                const assumed = assumeds.find((a) => {
                    return a.assumption_id === assumption_id && moment(a.assumed_date).isSame(moment(date), "day") && a.assumed_hour === hour;
                });
                let status = isOpen ? 'open' : 'close';
                if (assumed) {
                    status = assumed.assumed_done ? 'done' : 'not_done';
                }
                return {
                    ...assumption,
                    status,
                    assumed_hour: assumed ? assumed.assumed_hour : null,
                    index
                };
            }
        )
    };

    static schedulesToNotifications = (schedules) => {
        schedules = schedules.map((schedule) => {
            const {object_subtype, schedule_fulfilled} = schedule;
            if (!schedule_fulfilled) {
                let notification = schedule;
                if (object_subtype === E_VISIT) {
                    notification.color = 'successColor';
                    notification.icon = <EventIcon/>;
                } else if (object_subtype === E_QUESTIONAR) {
                    notification.color = 'primaryColor';
                    notification.icon = <DescriptionIcon color={'primary'}/>;
                }
                return notification;
            }
            return {};
        });
        return schedules.filter((schedule) => schedule.color);
    };
}

export default NotificationsService;

