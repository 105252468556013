import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from '../../../styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import VisitListItem from '../VisitListItem/VisitListItem';
import {withTranslation} from "../../../i18n/translator.provider";

const VisitHistory = ({classes, visits, translations: {TXT_NO_VISIT_PLANNED}}) => (
    <React.Fragment>
        {!!visits.length &&
        <Paper className={[classes.root, classes.m1].join(' ')}>
            <div className={classes.p1}>
                <List component="nav">
                    {visits.map((visit, index) => {
                        return (
                            <div key={index}>
                                <VisitListItem visit={visit}/>
                                {index !== visits.length - 1 && <Divider className={classes.my1}/>}
                            </div>
                        );
                    })}
                </List>
            </div>
        </Paper>}
        {!visits.length &&
        <Typography className={[classes.m1, classes.hintColor].join(' ')}
                    color={'inherit'} variant={'body1'} align={'center'}>{TXT_NO_VISIT_PLANNED}</Typography>}

    </React.Fragment>
);

VisitHistory.propTypes = {
    classes: PropTypes.object.isRequired,
    visits: PropTypes.array.isRequired
};

VisitHistory.defaultProps = {
    visits: []
};

export default withTranslation(['TXT_NO_VISIT_PLANNED'])(withStyles(styles)(VisitHistory));
