import React from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import DescriptionIcon from '@material-ui/icons/Description';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EventIcon from '@material-ui/icons/Event';
import Grid from '@material-ui/core/Grid';
import {styles} from '../../../styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import withStyles from '@material-ui/core/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';
import User from '../../../services/user.service';
import Drawer from '@material-ui/core/Drawer';
import ToggleNotification from '../../containers/notifications/toggle-notification.graphql';
import withApollo from 'react-apollo/withApollo';
import {FIREBASE_TOKEN} from '../../../constants';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import ChangeIdentityMutation from "../../containers/select-identity/change-identity.graphql";
import Snackbar from "@material-ui/core/Snackbar";
import {withTranslation} from "../../../i18n/translator.provider";
import {compose} from "react-apollo";

const AppDrawer = (props) => {
    const {
        classes, open, onClose, client, translations: {
            LBL_NOTIFICATIONS,
            LBL_CALENDAR,
            LBL_VISITS,
            LBL_PATIENT,
            LBL_QUESTIONARS,
            LBL_THERAPY,
            LBL_PROFILE,
            CTA_LOGOUT
        }
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const switchIsOpen = Boolean(anchorEl);
    const id = switchIsOpen ? 'simple-popover' : undefined;
    const [toast, setToast] = React.useState(false);
    const [toastMsg, setMessage] = React.useState('');

    function handleToastClose(event) {
        setToast(false);
    }

    function handleSwitchClose() {
        setAnchorEl(null);
    }

    const {display_name} = User.currentIdentity;
    const identities = User.identities;
    const logout = async () => {
        const firebaseToken = localStorage.getItem(FIREBASE_TOKEN);
        if (firebaseToken) {
            try {
                await client.mutate({
                    mutation: ToggleNotification,
                    variables: {
                        firebasetoken: firebaseToken,
                        enable: false
                    },
                    fetchPolicy: 'no-cache'
                });
            } catch (e) {
                console.error(e);
            }
        }
        await User.logOut(client);
        props.history.push('/login');
    };
    const openPopover = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const switchIdentity = async (e, key) => {
        e.stopPropagation();
        try {
            const {data: {ChangeIdentity}} = await client.mutate({
                mutation: ChangeIdentityMutation,
                variables: {
                    key: key
                }
            });
            User.login(ChangeIdentity);
            window.location.reload();
        } catch (e) {
            setMessage(e.message ? e.message : 'Ops! Qualcosa è andato storto. Riprova più tardi');
            setToast(true);
        }
    };

    const drawerList = [
        {
            label: <Typography variant={'subtitle1'} color={'secondary'}>{LBL_NOTIFICATIONS}</Typography>,
            icon: <NotificationsIcon color={'secondary'} className={classes.op5}/>,
            route: '/notifications'
        },
        {
            label: <Typography variant={'subtitle1'} color={'secondary'}>{LBL_THERAPY}</Typography>,
            icon: <LocalPharmacyIcon color={'secondary'} className={classes.op5}/>,
            route: '/therapy-list'
        },
        {
            label: <Typography variant={'subtitle1'} color={'secondary'}>{LBL_VISITS}</Typography>,
            icon: <EventIcon color={'secondary'} className={classes.op5}/>,
            route: '/visits'
        },
        {
            label: <Typography variant={'subtitle1'} color={'secondary'}>{LBL_QUESTIONARS}</Typography>,
            icon: <DescriptionIcon color={'secondary'} className={classes.op5}/>,
            route: '/questionars'
        },
        {
            label: <Typography variant={'subtitle1'} color={'secondary'}>{LBL_CALENDAR}</Typography>,
            icon: <CalendarTodayIcon color={'secondary'} className={classes.op5}/>,
            route: '/calendar'
        },
        {
            label: <Typography variant={'subtitle1'} color={'secondary'}>{LBL_PROFILE}</Typography>,
            icon: <AccountCircleIcon color={'secondary'} className={classes.op5}/>,
            route: '/profile'
        }
    ];
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const sideList = (
        <div className={classes.drawerWidth}>
            <Grid container
                  className={classes.py2}
                  direction={'column'}
                  justify={'center'}>
                <Grid item>
                    <Grid container justify="center">
                        <img className={classes.mb1} style={{height: '12vh'}} src="/logo-horizontal.svg"
                             alt="HuCare logo"/>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container
                          direction={'column'} className={classes.pl3}>
                        <Button className={classes.p0} onClick={openPopover} disabled={identities.length < 2}>
                            <Grid container justify={"space-between"} alignItems={"flex-end"}>
                                <Grid item xs>
                                    <Typography color={'secondary'} variant={'subtitle2'} className={classes.mb1}
                                                style={{textTransform: "capitalize"}} align={"left"}>
                                        <pre className={classes.m0}
                                             style={{textTransform: "capitalize"}}>{LBL_PATIENT}</pre>
                                        {display_name}
                                    </Typography>
                                </Grid>
                                {identities.length > 1 &&
                                <Grid item>
                                    <ArrowDropDownIcon className={classes.mr3} color={"secondary"}/>
                                </Grid>}
                            </Grid>
                        </Button>
                        <Popover
                            id={id}
                            open={switchIsOpen}
                            anchorEl={anchorEl}
                            onClose={handleSwitchClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}>
                            <Grid container>
                                <Grid item xs>
                                    <List component="ul" aria-label="Seleziona quale account utilizzare">
                                        {identities.filter(({code}) => code !== User.code).map(({display_name, key}) => (
                                            <ListItem component={'li'} button key={key}
                                                      onClick={(e) => switchIdentity(e, key)}>
                                                <Grid container wrap={"nowrap"} spacing={2} alignContent={"center"}>
                                                    <Grid item xs={2} container alignItems={"center"}>
                                                        <AccountCircleIcon color={"disabled"} fontSize={"small"}/>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant={"body2"} noWrap>{display_name}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>

                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                        </Popover>
                    </Grid>
                </Grid>
            </Grid>
            <Divider component={'hr'}/>
            <List component={'ul'}>
                {drawerList.map((item, index) => (
                    <ListItem button key={index} component={RouterLink} to={item.route}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.label}/>
                    </ListItem>
                ))}
            </List>
            <Divider component={'hr'}/>
            <List component={'ul'}>
                <ListItem button onClick={logout} component={'li'}>
                    <ListItemIcon><ExitToAppIcon className={classes.op5} color={'secondary'}/></ListItemIcon>
                    <ListItemText
                        primary={<Typography variant={'subtitle1'} color={'secondary'}>{CTA_LOGOUT}</Typography>}/>
                </ListItem>
            </List>
            <div className={[classes.appVersionWrapper, classes.m2].join(' ')}>
                <Typography variant={'caption'} align={'right'} color={'secondary'}>
                    v{process.env.REACT_APP_VERSION}
                </Typography>
            </div>
        </div>
    );

    return (
        <nav className={classes.drawer}>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
                <SwipeableDrawer open={open} onClose={onClose} onOpen={onClose}
                                 disableBackdropTransition={!iOS} disableDiscovery={iOS}>
                    <div
                        className={[classes.primaryBackground, classes.h100].join(' ')}
                        tabIndex={0}
                        role="button"
                        onClick={onClose}
                        onKeyDown={onClose}>
                        {sideList}
                    </div>
                </SwipeableDrawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer open={open} variant={'permanent'}>
                    <div
                        className={[classes.primaryBackground, classes.h100].join(' ')}
                        tabIndex={0}>
                        {sideList}
                    </div>
                </Drawer>
            </Hidden>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={toast}
                autoHideDuration={6000}
                onClose={handleToastClose}
                message={toastMsg}
            />
        </nav>
    );
};

AppDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};

export default compose(
    withTranslation([
        'LBL_NOTIFICATIONS',
        'LBL_CALENDAR',
        'LBL_VISITS',
        'LBL_PATIENT',
        'LBL_QUESTIONARS',
        'LBL_THERAPY',
        'LBL_PROFILE',
        'CTA_LOGOUT'
    ]),
    withRouter,
    withApollo,
    withStyles(styles)
)(AppDrawer);
