import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import {styles} from '../../../styles';
import AppHeader from '../../ui/Header/Header';
import AppDrawer from "../../ui/Drawer/Drawer";
import {compose, graphql} from "react-apollo";
import TherapyAssumptionsQuery from "../../containers/notifications/therapy-assumptions.query.graphql";
import moment from "moment";
import {fetchPolicy} from "../../../services/offline-manager";
import {E_THERAPY} from "../../../constants";
import TopNotificationQuery from "../../containers/notifications/top-notification.graphql";
import NotificationsService from "../../../services/notifications.service";
import {useStore} from "../../../store/store.context";
import {DELETE_THERAPY, SET_NOTIFICATIONS, SET_THERAPIES} from "../../../store/actions/actions";

const DefaultLayout = ({classes, children, title, header, sidebar, color, assumptions, assumeds, notifications}) => {
    let bodyStyle = {marginTop: '105px', padding: '5px 5px'};
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const {state: {therapies}, dispatch} = useStore();

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };
    const refreshNotificationCount = (e) => {
        dispatch({type: DELETE_THERAPY, assumption_id: e.detail.assumption_id, hour: e.detail.hour});
    };

    const notificationsCount = () => ((therapies ? therapies.length : 0) + (notifications ? notifications.length : 0));

    useEffect(
        () => {
            window.addEventListener('refresh-assumptions-count', refreshNotificationCount, false);
            return () => window.removeEventListener('refresh-assumptions-count', refreshNotificationCount, true);
        }, []
    );

    useEffect(
        () => {
            try {
                //after a week update therapies schedules
                if (assumptions && assumeds && !therapies) {
                    let therapies = NotificationsService.getTodayAssumptions(assumptions, assumeds);
                    therapies = therapies.filter(({status, life}) => status === 'open' && life >= 0);
                    dispatch({type: SET_THERAPIES, therapies});
                }
                if (notifications) {
                    dispatch({type: SET_NOTIFICATIONS, notifications});
                }
            } catch (e) {
                console.error('Error getting therapy notifications: ', e);
            }
        }, [assumptions, assumeds]
    );

    return (
        <main className={[classes.h100, classes[color]].join(' ')} id={sidebar ? 'sidebar-layout' : 'full-layout'}>
            <header className={classes.root}>
                {header &&
                <AppHeader title={title} toggleDrawer={toggleDrawer} nCount={notificationsCount()}/>}

                {sidebar && <AppDrawer open={isDrawerOpen} onClose={toggleDrawer}/>}
            </header>
            <section style={header ? bodyStyle : {height: '100%'}}
                     id={header ? 'header-layout' : 'headless-layout'}>
                {children}
            </section>
        </main>
    );
};

DefaultLayout.defaultProps = {
    header: true,
    sidebar: true,
    color: 'backGroundApp'
};

DefaultLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.string,
    header: PropTypes.bool.isRequired,
    sidebar: PropTypes.bool.isRequired
};

export default compose(
    graphql(TherapyAssumptionsQuery, {
        props: ({data}) => {
            if (!data.TherapyGetCalendar) {
                return {
                    loading: data.loading,
                };
            }
            return {
                assumptions: data.TherapyGetCalendar,
                loading: data.loading
            };
        },
        options: () => ({
                variables: {
                    ref_date: moment().format('DD-MM-YYYY'),
                    days: 0
                },
                fetchPolicy: fetchPolicy()
            }
        ),
        skip: ({filter}) => filter && filter !== E_THERAPY
    }),
    graphql(TopNotificationQuery, {
        props: ({data}) => {
            if (!data.Patient)
                return {
                    loading: data.loading,
                };
            let {schedules, assumeds} = data.Patient;
            return {
                assumeds,
                notifications: NotificationsService.schedulesToNotifications(schedules),
                loading: data.loading
            };
        },
        options: () => {
            return {
                variables: {
                    assumed_filters: [{
                        q: 'assumed_date',
                        op: 'eq',
                        value: moment().format('YYYY-MM-DD')
                    }],
                    schedules_filters: [
                        {
                            q: 'schedule_notify',
                            op: 'eq',
                            value: true
                        },
                        {
                            q: 'schedule_fulfilled',
                            op: 'eq',
                            value: false
                        },
                        {
                            q: 'schedule_due_date',
                            op: 'eq',
                            value: moment().format('YYYY-MM-DD')
                        }
                    ]
                },
                fetchPolicy: fetchPolicy()
            };
        }
    }),
    withStyles(styles)
)(DefaultLayout);
