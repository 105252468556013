
export default [
    {
        component: require('./components/containers/login/login').default,
        routePath: '/login',
        name: 'login',
        visibility: 'noAuth'
    },
    {
        component: require('./components/containers/calendar/calendar').default,
        routePath: '/calendar',
        name: 'calendar',
        title: 'LBL_CALENDAR',
        visibility: 'auth'
    },
    {
        component: require('./components/containers/visits/visits').default,
        routePath: '/visits',
        name: 'visits',
        title: 'LBL_VISITS',
        visibility: 'auth'
    },
    {
        component: require('./components/containers/questionars/questionars').default,
        routePath: '/questionars',
        name: 'questionars',
        title: 'LBL_QUESTIONARS',
        visibility: 'auth'
    },
    {
        component: require('./components/containers/notifications/notifications').default,
        routePath: '/notifications',
        name: 'questionars',
        title: 'LBL_NOTIFICATIONS',
        visibility: 'auth'
    },
    {
        component: require('./components/containers/questionar/questionar').default,
        routePath: '/questionar/',
        name: 'questionar',
        title: 'LBL_QUESTIONAR',
        visibility: 'auth'
    },
    {
        component: require('./components/containers/therapy-list/therapy-list').default,
        routePath: '/therapy-list',
        name: 'therapy',
        title: 'LBL_THERAPY',
        visibility: 'auth'
    },
    {
        component: require('./components/containers/therapy-details/therapy-details').default,
        routePath: '/therapy-details/:id',
        name: 'therapy-detail',
        title: 'LBL_THERAPY_DETAIL',
        visibility: 'auth'
    },
    {
        component: require('./components/containers/reminder/reminder').default,
        routePath: '/reminder/',
        name: 'reminder',
        title: 'TITLE_REMINDER',
        visibility: 'auth'
    },
    {
        component: require('./components/containers/profile/profile').default,
        routePath: '/profile',
        name: 'profile',
        title: 'LBL_PROFILE',
        visibility: 'auth'
    },
    {
        component: require('./components/containers/reset-password/reset-password').default,
        routePath: '/reset-password',
        name: 'reset-password',
        visibility: 'noAuth'
    },
    {
        component: require('./components/containers/select-identity/select-identity').default,
        routePath: '/select-identity',
        name: 'select-identity',
        visibility: 'auth'
    },
    {
        component: require('./components/containers/new-email-activation/new-email-activation').default,
        routePath: '/new-email-activation',
        name: 'new-email-activation',
        visibility: 'noAuth'
    },
    {
        component: require('./components/containers/error-500/error-500').default,
        routePath: '/error-500',
        name: 'error-500',
        visibility: 'all'
    },
    {
        component: require('./components/containers/error-403/error-403').default,
        routePath: '/error-403',
        name: 'error-403',
        visibility: 'all'
    },
    {
        component: require('./components/containers/signup/signup').default,
        routePath: '/complete-subscription',
        name: 'signup',
        visibility: 'noAuth'
    },
    {
        component: require('./components/containers/login/login').default,
        routePath: '/',
        name: 'login',
        visibility: 'noAuth'
    }
];
