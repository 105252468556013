import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {ALLOW_MOBILE_WEBSITE, APPLE_STORE_LINK, GOOGLE_PLAY_STORE_LINK} from '../../../constants';
import Button from '@material-ui/core/Button';
import {styles} from '../../../styles';
import withStyles from '@material-ui/core/styles/withStyles';
import withRouter from 'react-router-dom/withRouter';

const DownloadAppPage = props => {
    const getMobileOperatingSystem = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "android";
        }

        if (/android/i.test(userAgent)) {
            return "android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "ios";
        }
        return "android"; //if unknown most probable is android system! lol
    };

    const letsGo = () => {
        localStorage.setItem(ALLOW_MOBILE_WEBSITE, true);
        props.history.push('/');
    };

    return (
        <Grid container direction={'column'} justify={'center'}
              style={{
                  position: 'fixed',
                  left: '0',
                  right: '0',
                  top: '0',
                  bottom: '0',
                  background: '#0E88F1',
                  overflow: 'auto'
              }}
              spacing={3}>
            <Grid item>
                <Grid container justify={'center'}>
                    <Grid item xs={6}>
                        <img src="/logo.svg" alt="HuCare Trial Logo" style={{width: '100%', maxHeight: '20vh'}}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant={'h3'} align={'center'} color={'secondary'}>HuCare Trial</Typography>
                <Typography variant={'h6'} align={'center'}>Scarica l'app dallo store</Typography>
            </Grid>
            <Grid item>
                {getMobileOperatingSystem() === 'android' ?
                    <a href={GOOGLE_PLAY_STORE_LINK}>
                        <Grid container justify={'center'}>
                            <Grid item xs={6} sm={4}>
                                <img alt='Disponibile su Google Play'
                                     style={{width: '100%', height: 'auto'}}
                                     src='/images/icons/android/google-play-badge.png'/>
                            </Grid>
                        </Grid>
                    </a>
                    :
                    <a href={APPLE_STORE_LINK}>
                        <Grid container justify={'center'}>
                            <Grid item xs={6} sm={4}>
                                <img alt='Disponibile su Apple Store'
                                     style={{width: '100%', maxHeight: '100px', margin: '0 auto', display: 'block', height: '70px'}}
                                     src='/images/icons/ios/apple-store-badge.svg'/>
                            </Grid>
                        </Grid>
                    </a>}
            </Grid>
            <Grid style={{padding: 0}} item>
                <Typography variant={'h6'} align={'center'} style={{color: 'white'}}>oppure</Typography>
            </Grid>
            <Grid item>
                <Grid container justify={'center'}>
                    <Button variant={'outlined'} color={'secondary'} className={props.classes.mt2} onClick={letsGo}>prosegui
                        sul sito</Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(withStyles(styles)(DownloadAppPage));
